export const HEALTH_SCORE_COLORS = {
  A: '#97BA4C',
  B1: '#FFC566',
  B2: '#FF9F00',
  C1: '#FF9266',
  C2: '#FF4A00'
};
export const HEALTH_SCORES = Object.keys(HEALTH_SCORE_COLORS);
export const NUTRISCORES = ['A', 'B', 'C', 'D', 'E'];

export const PER_PAGE = 15;
export const PER_PAGE_FILTER = 50;

export const NUTRIENT_UNITS = {
  calcium: 'g/100g',
  protein: 'g/100g',
  energy: 'kJ/100g',
  energy_thermal: 'kcal/100g',
  iron: 'g/100g',
  natrium: 'g/100g',
  fat_trans: 'g/100g',
  fat_total: 'g/100g',
  fiber: 'g/100g',
  B12: 'g/100g',
  sugar: 'g/100g',
  fat_saturated: 'g/100g',
  salt: 'g/100g'
};

export const DEFAULT_AGE = 5 * 30; // days
export const DEFAULT_AGE_MAX = 24 * 30; // days
export const DEFAULT_AGE_MIN = 1 * 30; // days
