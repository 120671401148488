import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router';

// Containers
import Login from './Login';
import Signup from './Signup';
import ResetPassword from './ResetPassword';
import Products from './Products';
import ProductDetail from './ProductDetail';
import Profile from './Profile';
import Brands from './Brands';
import Comparisons from './Comparisons';
import ComparisonDetail from './ComparisonDetail';
import MetabaseEmbed from './MetabaseEmbed';

// Components
import NotFound from '../components/NotFound';
import Forbidden from '../components/Forbidden';
import Loader from '../components/Loader';

function Routes({ loggedIn, loading }) {
  function Auth(AuthedComponent) {
    if (loading && !loggedIn) {
      return () => <Loader loading={loading} center padded />;
    }
    if (loggedIn) return AuthedComponent;
    return null;
  }

  return (
    <Switch>
      <Route path="/" render={() => <Redirect to="/products" />} exact />

      <Route path="/login" component={Login} />
      <Route path="/signup" component={Signup} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/products" component={Auth(Products)} exact />
      <Route path="/products/:id" component={Auth(ProductDetail)} />
      <Route path="/brands" component={Auth(Brands)} />
      <Route path="/profile" component={Auth(Profile)} />
      <Route path="/comparisons" component={Auth(Comparisons)} exact />
      <Route path="/comparisons/:id" component={Auth(ComparisonDetail)} />
      <Route path="/metabase/:type/:id" component={Auth(MetabaseEmbed)} />

      <Route path="/403" component={Forbidden} status={403} />
      <Route path="*" component={NotFound} status={404} />
    </Switch>
  );
}

Routes.propTypes = {
  loggedIn: PropTypes.bool,
  loading: PropTypes.bool
};

export default Routes;
