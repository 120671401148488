export const FETCH_COMPARISONS = 'FETCH_COMPARISONS';
export const FETCH_COMPARISONS_REQUEST = `${FETCH_COMPARISONS}_REQUEST`;
export const FETCH_COMPARISONS_SUCCESS = `${FETCH_COMPARISONS}_SUCCESS`;
export const FETCH_COMPARISONS_FAILURE = `${FETCH_COMPARISONS}_FAILURE`;

export const CREATE_COMPARISON = 'CREATE_COMPARISON';
export const CREATE_COMPARISON_REQUEST = `${CREATE_COMPARISON}_REQUEST`;
export const CREATE_COMPARISON_SUCCESS = `${CREATE_COMPARISON}_SUCCESS`;
export const CREATE_COMPARISON_FAILURE = `${CREATE_COMPARISON}_FAILURE`;

export const UPDATE_COMPARISON = 'UPDATE_COMPARISON';
export const UPDATE_COMPARISON_REQUEST = `${UPDATE_COMPARISON}_REQUEST`;
export const UPDATE_COMPARISON_SUCCESS = `${UPDATE_COMPARISON}_SUCCESS`;
export const UPDATE_COMPARISON_FAILURE = `${UPDATE_COMPARISON}_FAILURE`;

export const REMOVE_COMPARISON = 'REMOVE_COMPARISON';
export const REMOVE_COMPARISON_REQUEST = `${REMOVE_COMPARISON}_REQUEST`;
export const REMOVE_COMPARISON_SUCCESS = `${REMOVE_COMPARISON}_SUCCESS`;
export const REMOVE_COMPARISON_FAILURE = `${REMOVE_COMPARISON}_FAILURE`;

export function fetchComparisons(params) {
  return { type: FETCH_COMPARISONS, ...params };
}

export function createComparison(name, products) {
  return { type: CREATE_COMPARISON, name, products };
}

export function updateComparison(name, products, id, remove) {
  return { type: UPDATE_COMPARISON, name, products, id, remove };
}

export function removeComparison(id) {
  return { type: REMOVE_COMPARISON, id };
}
