import LocalStore from 'store';
import uniqBy from 'lodash/fp/uniqBy';
import ShortId from 'shortid';

// This is needed because url numbers are converted to strings during pushstate
// operation. While removing comparisons / product in a comparison
// we would have to convert them to strings.
const idsEqual = (id1, id2) => id1.toString() === id2.toString();

export function list() {
  return LocalStore.get('comparisons') || [];
}

export function add(name, products = []) {
  if (exists(name)) throw new Error(`The name ${name} already exists`);
  const comparisons = list();
  const id = ShortId.generate();
  const comparison = {
    id,
    name,
    products
  };
  comparisons.unshift(comparison);
  return save(comparisons);
}

function save(comparisons) {
  LocalStore.set('comparisons', comparisons);
  return list();
}

function exists(name) {
  return list()
    .map((c) => c.name)
    .includes(name);
}

export function remove(id) {
  const comparisons = list().filter((c) => !idsEqual(c.id, id));
  return save(comparisons);
}

export function updateProducts(name, products, id) {
  const comparisons = list().map((c) => {
    if (!idsEqual(c.id, id)) return c;
    c.products = uniqBy('id')([...products, ...c.products]);
    c.name = name;
    return c;
  });
  return save(comparisons);
}

export function removeProducts(products, id) {
  const comparisons = list().map((c) => {
    if (!idsEqual(c.id, id)) return c;
    c.products = c.products.filter(
      (p) => !products.map((q) => q.id).includes(p.id)
    );
    return c;
  });
  return save(comparisons);
}
