import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DropdownButton, MenuItem } from 'react-bootstrap';

class Selector extends Component {
  render() {
    const { selected, columns, onSelect, accessor, children, ...rest } =
      this.props;
    return (
      <DropdownButton title={children} id="column-selector" {...rest}>
        {columns.map((c, index) => (
          <MenuItem eventKey={index} key={index} onClick={(e) => onSelect(c)}>
            {c.name}
            {/* text as workaround for https://github.com/q-m/qmi-dashboard/issues/89 */}
            {selected.includes(accessor ? c[accessor] : c) ? ' ✔' : ''}
          </MenuItem>
        ))}
      </DropdownButton>
    );
  }
}

Selector.propTypes = {
  selected: PropTypes.string,
  columns: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
  accessor: PropTypes.string,
  children: PropTypes.string
};

Selector.defaultProps = {
  selected: '',
  columns: [],
  onSelect: () => {},
  accessor: '',
  children: 'Columns'
};

export default Selector;
