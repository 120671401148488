import React from 'react';
import PropTypes from 'prop-types';

import Filter from './Filter';
import { fetchBrands, appendBrands } from '../../actions/brands';

export default function Brands(props) {
  return (
    <Filter
      value={props.value}
      title="BRANDS"
      searchKey="brands[q]"
      fetcher={fetchBrands}
      appender={appendBrands}
      stateName="brands"
      attr="brand_ids"
      disabled={props.disabled}
    />
  );
}

Brands.propTypes = {
  value: PropTypes.array
};
