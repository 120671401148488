const styles = {
  button: {
    color: '#333',
    cursor: 'pointer',
    fontStyle: 'italic',
    // put button on top of (empty) first cell of svg table
    position: 'absolute',
    marginTop: 12,
    marginLeft: 10,
    zIndex: 1
  },
  scoreRowHeader: {
    width: '15em',
    borderRight: '2px solid #ddd' // @todo get from bootstrap settings
  },
  scoreCell: {
    width: '7em',
    textAlign: 'center'
  },
  ghostBullet: {
    opacity: 0.3
  },
  actionCell: {
    border: 'none',
    fontStyle: 'italic'
  },
  actionArrow: {
    paddingLeft: 10,
    paddingRight: 10
  },
  sectionPara: {
    marginTop: 40
  }
};

export default styles;
