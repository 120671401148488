import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import branding from 'questionmark-branding';

function DetailSwitch({ active, onClick, ...props }) {
  return (
    <small onClick={onClick && (() => onClick(!active))} {...props}>
      <span style={styles.switchContainer}>
        <Switch disabled checked={active} onColor={branding.paradisolight} />
      </span>
      <span style={styles.text}>details</span>
    </small>
  );
}

DetailSwitch.propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func
};

const styles = {
  switchContainer: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: 6
  },
  text: {
    display: 'inline-block'
  }
};

export default DetailSwitch;
