import {
  FETCH_RETAILERS_REQUEST,
  FETCH_RETAILERS_SUCCESS,
  FETCH_RETAILERS_FAILURE,
  APPEND_RETAILERS_REQUEST,
  APPEND_RETAILERS_SUCCESS,
  APPEND_RETAILERS_FAILURE
} from '../actions/retailers';
import { idToString } from '../lib/helpers';

const initialState = {
  loading: false,
  items: [],
  total: 0
};

export default function retailers(state = initialState, action) {
  switch (action.type) {
    case APPEND_RETAILERS_REQUEST:
    case FETCH_RETAILERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_RETAILERS_SUCCESS:
      return {
        ...state,
        total: action.payload.total,
        items: action.payload.retailers.map(idToString),
        loading: false
      };
    case APPEND_RETAILERS_SUCCESS:
      return {
        ...state,
        items: state.items.concat(
          action.payload.retailers
            .filter((i) =>
              action.payload.params.retailer_ids.includes(i.id.toString())
            )
            .map(idToString)
        ),
        loading: false
      };
    case APPEND_RETAILERS_FAILURE:
    case FETCH_RETAILERS_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
