import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';

function Nutriscore({ score, ...rest }) {
  if (!score) return null;
  if (!['A', 'B', 'C', 'D', 'E'].includes(score)) return null; // security
  return (
    <Image
      src={`/images/nutriscore-${score.toLowerCase()}.svg`}
      height={50}
      width={90}
      alt={score}
      {...rest}
    />
  );
}

Nutriscore.propTypes = {
  score: PropTypes.oneOf(['A', 'B', 'C', 'D', 'E'])
};

export default Nutriscore;
