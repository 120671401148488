import { t } from '../i18n';
import store from '../store';

export function getOwnedBrandIds() {
  const state = store.getState();
  return state.user.owned_brands.map((b) => b.id);
}

export function isOwned(brand_id) {
  return getOwnedBrandIds().includes(brand_id);
}

export function idToString({ name, id }) {
  return {
    name,
    id: id.toString()
  };
}

export function apiErrorMessage(res) {
  // low-level API error response
  if (res.error) return res.error;

  // higher-level API error response
  if (res.errors && res.errors.length > 0) return res.errors[0].detail;

  // fallback
  return t('PLATFORM_ISSUES');
}
