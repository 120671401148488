import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { t } from '../../i18n';

const tooltip = (
  <Tooltip placement="top" className="in" id="tooltip-top">
    {t('UNVALIDATED_INFO')}
  </Tooltip>
);

function Unvalidated({ expanded }) {
  const icon = (
    <span>
      <i className="glyphicon glyphicon-refresh" style={{ color: '#999' }}></i>
    </span>
  );
  if (expanded) {
    return (
      <div>
        <p>{icon}</p>
        {t('UNVALIDATED_INFO')}
      </div>
    );
  }
  return (
    <OverlayTrigger placement="top" overlay={tooltip}>
      {icon}
    </OverlayTrigger>
  );
}

export default Unvalidated;
