import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Loader from '../../components/Loader';
import NoResults from '../../components/NoResults';
import Toolbar from '../../components/Toolbar';
import EditComparison from '../../components/Comparisons/AddEdit';
import Beta from '../../components/Beta';
import { t } from '../../i18n';
import {
  fetchComparisons,
  removeComparison,
  updateComparison
} from '../../actions/comparisons';

export class Comparisons extends Component {
  componentDidMount() {
    this.props.dispatch(fetchComparisons());
  }

  render() {
    const { items, loading } = this.props;
    return (
      <Grid>
        <Toolbar>
          <h2>
            {t('COMPARISONS')}{' '}
            <small>
              ({items.length}) <Beta />
            </small>
          </h2>
        </Toolbar>
        <Table responsive hover>
          <thead>
            <tr>
              <th width="40%">{t('NAME')}</th>
              <th>{t('PRODUCTS')}</th>
              <th width="80px"></th>
              <th width="80px"></th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={index}>
                <td>
                  <Link to={`/comparisons/${item.id}`}>{item.name}</Link>
                </td>
                <td>{item.products.length}</td>
                <td>
                  <EditComparison
                    comparison={item}
                    onUpdate={(name, selected, id) =>
                      this.onUpdate(name, selected, id)
                    }>
                    <i className="glyphicon glyphicon-edit"></i>
                  </EditComparison>
                </td>
                <td>
                  <a
                    href=""
                    onClick={(e) => this.remove(e, item)}
                    className="brand-danger">
                    <i className="glyphicon glyphicon-trash"></i>
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {!loading && !items.length && <NoResults center />}
        <Loader loading={loading} center padded />
      </Grid>
    );
  }

  remove(e, c) {
    e.preventDefault();
    if (window.confirm('Are you sure?')) {
      this.props.dispatch(removeComparison(c.id));
    }
  }

  onUpdate(name, selected, id) {
    this.props.dispatch(updateComparison(name, selected, id));
  }
}

Comparisons.propTypes = {
  items: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired
};

function select(state, props) {
  return {
    ...state.comparisons
  };
}

export default connect(select)(Comparisons);
