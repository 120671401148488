import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

function Toolbar({ left, right, active, children }) {
  const toolbar = (
    <div
      className={classnames('toolbar flex-row middle-xs between-xs', {
        active: active
      })}>
      {left || children}
      {right}
    </div>
  );
  if (!active) return toolbar;
  return <div style={{ position: 'sticky', top: 0, zIndex: 2 }}>{toolbar}</div>;
}

Toolbar.propTypes = {
  left: PropTypes.element,
  right: PropTypes.element,
  children: PropTypes.element,
  active: PropTypes.bool
};

export default Toolbar;
