import React from 'react';
import PropTypes from 'prop-types';

import Filter from './Filter';
import {
  fetchCertificates,
  appendCertificates
} from '../../actions/certificates';

export default function Certificates(props) {
  return (
    <Filter
      value={props.value}
      title="CERTIFICATES"
      searchKey="certificates[q]"
      fetcher={fetchCertificates}
      appender={appendCertificates}
      stateName="certificates"
      attr="certificate_ids"
    />
  );
}

Certificates.propTypes = {
  value: PropTypes.array
};
