import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route } from 'react-router';
import { Grid } from 'react-bootstrap';
import isEqual from 'lodash/fp/isEqual';
import LocalStore from 'store';

import Loader from '../../components/Loader';
import NotFound from '../../components/NotFound';
import Comparisons from '../../components/Comparisons';
import Toolbar from '../../components/Toolbar';
import SearchBox from '../../components/SearchBox';
import Selector from '../../components/Selector';
import NoResults from '../../components/NoResults';
import Beta from '../../components/Beta';

import { t } from '../../i18n';
import { fetchComparisons, updateComparison } from '../../actions/comparisons';
import { fetchNutrients } from '../../actions/nutrients';
import { addQuery } from '../../lib/query-params';

// Nutrient codes that need ascending sort in the graph
const highToLow = ['calcium', 'iron', 'fiber', 'A', 'B12', 'C'];

class ComparisonDetail extends Component {
  componentDidMount() {
    this.props.dispatch(fetchNutrients({ per_page: 30 }));
    this.props.dispatch(fetchComparisons());
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextProps)(this.props);
  }

  render() {
    const { loading, comparison, nutrients, columns } = this.props;
    if (!comparison) {
      return loading ? (
        <Grid>
          <br />
          <Loader loading={loading} />
        </Grid>
      ) : (
        <Route component={NotFound} />
      );
    }
    const SelectNutrients = (
      <Selector
        selected={columns}
        columns={nutrients.map((n) => ({
          ...n,
          name: t(`NUTRIENT_${n.code.toUpperCase()}`)
        }))}
        accessor="code"
        onSelect={(c) => this.onColumnSelect(c)}
        pullRight>
        {t('NUTRIENTS')}
      </Selector>
    );
    const selected = nutrients.filter((n) =>
      columns.split(',').includes(n.code)
    );
    const ComparisonNutrients = (
      <ul className="list list-unstyled list-qmi">
        {selected
          .map((nutrient) => ({
            nutrient,
            data: getData(comparison.products, nutrient)
          }))
          .map((obj, index) => (
            <li key={index}>
              <div className="nutrient">
                <h4>
                  {t(`NUTRIENT_${obj.nutrient.code.toUpperCase()}`)}{' '}
                  <small>{obj.nutrient.unit}</small>
                </h4>
                <br />
                {obj.data.length > 0 ? (
                  <Comparisons.Nutrient
                    nutrient={obj.nutrient}
                    data={obj.data}
                  />
                ) : (
                  <NoResults center />
                )}
              </div>
            </li>
          ))}
      </ul>
    );
    return (
      <Grid fluid>
        <Toolbar right={SelectNutrients}>
          <h2>
            {comparison.name}{' '}
            <small>
              ({comparison.products.length}) <Beta />
            </small>
          </h2>
        </Toolbar>
        <Loader loading={loading} />
        <div className="flex-row comparison-detail">
          <div className="flex-col-xs-3 products-list">
            <SearchBox
              onSelect={(p) => this.addProduct(p)}
              placeholder={t('ADD_PRODUCT_TO_COMPARISON')}
            />
            <Comparisons.ProductList
              items={comparison.products}
              onRemove={(e, p) => this.onRemove(e, p)}
            />
          </div>
          <div className="flex-col-xs-9 nutrients">
            {comparison.products.length ? (
              ComparisonNutrients
            ) : (
              <NoResults center>{t('NO_COMPARISON_PRODUCTS')}</NoResults>
            )}
          </div>
        </div>
      </Grid>
    );
  }

  addProduct(product) {
    const { comparison, dispatch } = this.props;
    dispatch(updateComparison(comparison.name, [product], comparison.id));
  }

  onRemove(e, product) {
    const { dispatch, comparison } = this.props;
    e.preventDefault();
    dispatch(updateComparison(comparison.name, [product], comparison.id, true));
  }

  onColumnSelect(col) {
    const current = (this.props.columns || '').split(',').filter((c) => c);
    const columns = current.includes(col.code)
      ? current.filter((c) => c !== col.code).join(',')
      : current.concat([col.code]).join(',');
    addQuery({ columns });

    // Remember in localstorage
    const filters = LocalStore.get('filters');
    filters.columns = columns;
    LocalStore.set('filters', filters);
  }
}

ComparisonDetail.propTypes = {
  comparison: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  nutrients: PropTypes.array,
  columns: PropTypes.string
};

function select(state, props) {
  const filters = LocalStore.get('filters') || {};
  const { id } = props.match.params;
  const { loading, items } = state.comparisons;
  const comparison = items.filter((item) => item.id === id)[0];
  return {
    comparison,
    loading,
    nutrients: state.nutrients.items,
    columns:
      props.location.query.columns || filters.columns || 'energy,sugar,salt'
  };
}

export default connect(select)(ComparisonDetail);

function getData(products, nutrient) {
  return products
    .map((p) => transform(p, nutrient.code))
    .filter((d) => typeof d.value !== 'undefined')
    .sort((a, b) =>
      // Some grahps need to be shown from high to low
      highToLow.includes(nutrient.code) ? a.value - b.value : b.value - a.value
    );
}

function transform(product, code) {
  const rawVal = product.product_nutrients
    .filter((n) => n.code === code)
    .reduce((o, n) => n || o, {}).raw_value;
  return {
    id: product.id,
    name: product.name,
    brand: product.brand_name,
    brand_id: product.brand_id,
    value: rawVal
  };
}
