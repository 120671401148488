import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Iframe from 'react-iframe';

function MetabaseEmbed({ id, type }) {
  const [height, setHeight] = useState(400);

  // Set container height
  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div style={{ height: height - 60 }}>
      <Iframe
        url={`/metabase/embed/${type}/${id}#bordered=false&titled=true`}
        frameborder={0}
        position="static"
        width="100%"
        height="100%"
        style={{ minHeight: 600 }}
        allowtransparency
      />
    </div>
  );
}

MetabaseEmbed.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired
};

function select(state, props) {
  return {
    type: props.match.params.type,
    id: parseInt(props.match.params.id, 10)
  };
}

export default connect(select)(MetabaseEmbed);
