export default function product(product) {
  const {
    product_listed_ingredients,
    product_nutrients,
    product_nutrients_prepared,
    attribute_metadata,
    producer_name,
    retailers,
    refrigeration_type,
    package_name,
    nutriscore,
    personal_health_score,
    unvalidated_data,
    comparison_group,
    confirmed_at,
    certificates,
    theme_scores,
    image_urls,
    brand_name,
    barcodes,
    brand_id,
    usages,
    brand,
    score,
    name,
    id
  } = product;

  const themeScore = (theme) =>
    (theme_scores.find((s) => s.theme_key === theme) || {}).score;
  const image_url = (image_urls || [])[0];

  return {
    product_listed_ingredients,
    product_nutrients,
    product_nutrients_prepared,
    producer_name,
    retailers,
    refrigeration_type,
    package_name,
    unvalidated_data,
    nutriscore,
    personal_health_score,
    columns: {
      sustainability_score: score,
      nutriscore: nutriscore,
      health_score: personal_health_score,
      social_score: themeScore('social'),
      environment_score: themeScore('environment'),
      animals_score: themeScore('animals'),
      ...nutrientInfo(product_nutrients)
    },
    attribute_metadata,
    comparison_group,
    confirmed_at,
    certificates,
    brand_name,
    barcodes,
    barcode: barcodes && barcodes.length > 0 && barcodes[0],
    brand_id: brand_id || (brand || {}).id,
    usage: (usages.length && usages[usages.length - 1]) || {},
    image: image_url || {},
    images: image_urls || [],
    name,
    id
  };
}

// Input product_nutrients array
// Output:
//  {
//    nutrient_<code>_benchmark
//    nutrient_<code>_value
//    nutrient_<code>_unit
//  }

function nutrientInfo(nutrients) {
  return nutrients
    .filter((n) => n.code) // nutrients with code
    .reduce(function (_values, nutrient) {
      if (!nutrient.code) return _values;
      const unit = getUnit(nutrient);
      if (unit) _values[`${nutrient.code}_unit`] = unit;
      _values[`${nutrient.code}_value`] = nutrient.value;
      return _values;
    }, {});
}

function getUnit(nutrient) {
  const [unit] = nutrient && nutrient.unit && nutrient.unit.split('/');
  const hide = ['g', 'kcal', 'kJ']; // we don't want to show units for these coz they are default
  // but we must show it for `mg`
  return hide.includes(unit) ? '' : unit;
}
