import pkg from '../../package.json';

export const appName = pkg.name;

const config = window.__config__;

export const APP_ENV = config.REACT_APP_ENV || process.env.REACT_APP_ENV;
export const API_ROOT =
  config.REACT_APP_QM_API_ROOT || process.env.REACT_APP_QM_API_ROOT;
export const BUGSNAG_API_KEY =
  config.REACT_APP_BUGSNAG_API_KEY || process.env.REACT_APP_BUGSNAG_API_KEY;

export const isProduction = APP_ENV === 'production';
export const isDev = APP_ENV === 'development';
