import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Col, FormControl, ControlLabel } from 'react-bootstrap';

function Field({
  label,
  onChange,
  value,
  name,
  type,
  required,
  dirty,
  inputSize
}) {
  const inputProps = {};
  if (inputSize) inputProps.bsSize = 'lg';
  return (
    <FormGroup
      controlId={name}
      validationState={(required && !value && dirty && 'error') || null}>
      <Col componentClass={ControlLabel} sm={2}>
        {label}
        {required ? '*' : ''}
      </Col>
      <Col sm={6}>
        <FormControl
          required={!!required}
          name={name}
          value={value || ''}
          type={type || 'text'}
          placeholder={label}
          onChange={onChange}
          {...inputProps}
        />
      </Col>
    </FormGroup>
  );
}

Field.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  dirty: PropTypes.bool,
  inputSize: PropTypes.string
};

Field.defaultProps = {
  inputSize: ''
};

export default Field;
