import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup, FormControl } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import ButtonWithLoader from '../../components/ButtonWithLoader';

import { t } from '../../i18n';
import { login } from '../../actions/user';

class Login extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
    this.state = {
      email: '',
      password: ''
    };
  }

  render() {
    const { loading } = this.props;
    const { email, password } = this.state;
    const enabled = !loading && email && password;
    return (
      <Form className="form-auth">
        <h2>{t('LOGIN')}</h2>
        <FormGroup bsSize="large">
          <FormControl
            name="email"
            type="email"
            placeholder={t('EMAIL')}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup bsSize="large">
          <FormControl
            name="password"
            type="password"
            placeholder={t('PASSWORD')}
            onChange={this.handleChange}
          />
        </FormGroup>
        <p>
          <Link to="/reset-password">{t('FORGOT_PASSWORD')}</Link>
        </p>
        <ButtonWithLoader
          type="submit"
          disabled={!enabled}
          onClick={this.submit}
          bsSize="large"
          bsStyle="primary"
          loading={loading}>
          {t('LOGIN')}
        </ButtonWithLoader>
      </Form>
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value }); // update state with changed values
  }

  submit(e) {
    const { email, password } = this.state;
    e.preventDefault();
    this.props.dispatch(login(email, password));
  }
}

Login.propTypes = {
  loggedIn: PropTypes.bool,
  loading: PropTypes.bool
};

function select(state) {
  return {
    ...state.user
  };
}

export default connect(select)(Login);
