import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'react-bootstrap';
import { isOwned } from '../../lib/helpers';

function BrandLabel({ brand_id, children }) {
  const owned = isOwned(brand_id);
  const style = owned ? {} : { backgroundColor: '#bbb' };
  return (
    <Label bsStyle={owned ? 'primary' : 'default'} style={style}>
      {children}
    </Label>
  );
}

BrandLabel.propTypes = {
  brand_id: PropTypes.number,
  children: PropTypes.string
};

export default BrandLabel;
