import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup, Col } from 'react-bootstrap';
import QMField from '../../components/Field';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import { t } from '../../i18n';
import { acceptInvite, fetchInvite } from '../../actions/user';

const Field = (props) => (
  <QMField inputSize="lg" {...props} value={props.value || ''} />
);

class Signup extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
    this.acceptTerms = this.acceptTerms.bind(this);
    this.state = {
      user: {
        invitation_token: props.query.invitation_token,
        name: '',
        email: '',
        phone: '',
        company_name: '',
        position: '',
        department: '',
        address: '',
        zipcode: '',
        city: '',
        terms_accepted: true
      }
    };
  }

  componentDidMount() {
    const { invitation_token } = this.props.query;
    if (!invitation_token) return;
    this.props.dispatch(fetchInvite(invitation_token));
  }

  render() {
    const { loading, query } = this.props;
    const { invitation_token } = query;
    const {
      name,
      email,
      password,
      phone,
      company_name,
      position,
      department,
      address,
      zipcode,
      city
    } = this.state.user;
    const enabled = !loading && email && password;
    return (
      <div className="container">
        <div className="text-center">
          <h2>{t('SIGN_UP')}</h2>
          {!invitation_token && t('MSG_MUST_BE_INVITED')}
        </div>
        {invitation_token && (
          <Form horizontal className="col-md-9 col-md-offset-3">
            <Field
              label={t('NAME')}
              name="name"
              value={name}
              onChange={this.handleChange}
              required
            />

            <Field
              label={t('EMAIL')}
              name="email"
              value={email}
              onChange={this.handleChange}
              type="email"
              required
            />

            <Field
              label={t('PASSWORD')}
              name="password"
              value={password}
              onChange={this.handleChange}
              type="password"
              required
            />

            <Field
              label={t('PHONE')}
              name="phone"
              value={phone}
              onChange={this.handleChange}
              required
            />

            <Field
              label={t('COMPANY_NAME')}
              name="company_name"
              value={company_name}
              onChange={this.handleChange}
              required
            />

            <Field
              label={t('POSITION')}
              name="position"
              value={position}
              onChange={this.handleChange}
              required
            />

            <Field
              label={t('DEPARTMENT')}
              name="department"
              value={department}
              onChange={this.handleChange}
              required
            />

            <Field
              label={t('CITY')}
              name="city"
              value={city}
              onChange={this.handleChange}
              required
            />

            <Field
              label={t('ADDRESS')}
              name="address"
              value={address}
              onChange={this.handleChange}
            />

            <Field
              label={t('ZIPCODE')}
              name="zipcode"
              value={zipcode}
              onChange={this.handleChange}
            />

            {/* @todo Perhaps we want to add it back later? */}
            {/* <FormGroup>
            <Col smOffset={2} sm={10}>
              <Checkbox onChange={this.acceptTerms} required>
                {t('I_ACCEPT')} <a href="http://www.qmintelligence.com/algemene-voorwaarden" target="_blank">{t('TERMS_CONDITIONS')}</a>
              </Checkbox>
            </Col>
          </FormGroup> */}

            <FormGroup>
              <Col smOffset={2} sm={4}>
                <ButtonWithLoader
                  type="submit"
                  disabled={!enabled}
                  onClick={this.submit}
                  bsSize="large"
                  bsStyle="primary"
                  loading={loading}>
                  {t('SIGN_UP')}
                </ButtonWithLoader>
              </Col>
            </FormGroup>
          </Form>
        )}
      </div>
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    const user = { ...this.state.user, [name]: value };
    this.setState({ user });
  }

  submit(e) {
    const { user } = this.state;
    user.terms_accepted = true;
    const { invitation_token } = this.props.query;
    e.preventDefault();
    this.props.dispatch(acceptInvite(user, invitation_token));
  }

  acceptTerms(e) {
    const { checked: terms_accepted } = e.target;
    this.setState({ user: { ...this.state.user, terms_accepted } });
  }
}

Signup.propTypes = {
  dispatch: PropTypes.func,
  loading: PropTypes.bool,
  query: PropTypes.object,
  user: PropTypes.object
};

function select(state, props) {
  const { query } = props.location;
  return {
    user: state.user,
    loading: state.user.loading,
    query
  };
}

export default connect(select)(Signup);
