import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { t } from '../../i18n';

/**
 * <Loader loading={bool} text="Please wait..." />
 */

function Loader({ loading, center, padded, text }) {
  if (!loading) return null;
  return (
    <div
      className={classnames('loading flex-row', {
        'center-xs': center,
        padded: padded
      })}>
      <Dots />
      <div>{text || t('LOADING') + '...'}</div>
    </div>
  );
}

Loader.propTypes = {
  loading: PropTypes.bool.isRequired,
  center: PropTypes.bool,
  padded: PropTypes.bool,
  text: PropTypes.string
};

Loader.defaultProps = {
  loading: false
};

/**
 * <Dots />
 */

export function Dots({ size, color, spacing, ...rest }) {
  rest.style = rest.style || {};
  const style = {
    width: size + 'px',
    height: size + 'px',
    backgroundColor: color,
    marginRight: spacing + 'px'
  };
  return (
    <div className="three-bounce" style={{ width: 'auto', ...rest.style }}>
      <div className="one" style={style}></div>
      <div className="two" style={style}></div>
      <div className="three" style={style}></div>
    </div>
  );
}

Dots.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  spacing: PropTypes.number
};

Dots.defaultProps = {
  size: 10,
  color: '#aaa',
  spacing: 4
};

export default Loader;
