import {
  FETCH_CERTIFICATES_REQUEST,
  FETCH_CERTIFICATES_SUCCESS,
  FETCH_CERTIFICATES_FAILURE,
  APPEND_CERTIFICATES_REQUEST,
  APPEND_CERTIFICATES_SUCCESS,
  APPEND_CERTIFICATES_FAILURE
} from '../actions/certificates';
import { idToString } from '../lib/helpers';

const initialState = {
  loading: false,
  items: [],
  total: 0
};

export default function certificates(state = initialState, action) {
  switch (action.type) {
    case APPEND_CERTIFICATES_REQUEST:
    case FETCH_CERTIFICATES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_CERTIFICATES_SUCCESS:
      return {
        ...state,
        total: action.payload.total,
        items: action.payload.certificates.map(idToString),
        loading: false
      };
    case APPEND_CERTIFICATES_SUCCESS:
      return {
        ...state,
        items: state.items.concat(
          action.payload.certificates
            .filter((i) =>
              action.payload.params.certificate_ids.includes(i.id.toString())
            )
            .map(idToString)
        ),
        loading: false
      };
    case APPEND_CERTIFICATES_FAILURE:
    case FETCH_CERTIFICATES_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
