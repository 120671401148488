import {
  FETCH_BRANDS_REQUEST,
  FETCH_BRANDS_SUCCESS,
  FETCH_BRANDS_FAILURE,
  APPEND_BRANDS_REQUEST,
  APPEND_BRANDS_SUCCESS,
  APPEND_BRANDS_FAILURE
} from '../actions/brands';
import { idToString } from '../lib/helpers';

const initialState = {
  loading: false,
  items: [],
  total: 0
};

export default function brands(state = initialState, action) {
  switch (action.type) {
    case APPEND_BRANDS_REQUEST:
    case FETCH_BRANDS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_BRANDS_SUCCESS:
      return {
        ...state,
        total: action.payload.total,
        items: action.payload.brands.map(idToString),
        loading: false
      };
    case APPEND_BRANDS_SUCCESS:
      return {
        ...state,
        items: state.items.concat(
          action.payload.brands
            .filter((i) => action.payload.params.ids.includes(i.id.toString()))
            .map(idToString)
        ),
        loading: false
      };
    case APPEND_BRANDS_FAILURE:
    case FETCH_BRANDS_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
