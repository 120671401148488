import React from 'react';
import { Label, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { t } from '../../i18n';

const tooltip = (
  <Tooltip placement="top" className="in" id="tooltip-top">
    {t('BETA_INFO')}
  </Tooltip>
);

function Beta() {
  return (
    <OverlayTrigger placement="top" overlay={tooltip}>
      <Label bsStyle="warning" style={{ opacity: 0.75 }}>
        <i>{t('BETA_LABEL')}</i>
      </Label>
    </OverlayTrigger>
  );
}

export default Beta;
