export const FETCH_BRANDS = 'FETCH_BRANDS';
export const FETCH_BRANDS_REQUEST = `${FETCH_BRANDS}_REQUEST`;
export const FETCH_BRANDS_SUCCESS = `${FETCH_BRANDS}_SUCCESS`;
export const FETCH_BRANDS_FAILURE = `${FETCH_BRANDS}_FAILURE`;

export const APPEND_BRANDS = 'APPEND_BRANDS';
export const APPEND_BRANDS_REQUEST = `${APPEND_BRANDS}_REQUEST`;
export const APPEND_BRANDS_SUCCESS = `${APPEND_BRANDS}_SUCCESS`;
export const APPEND_BRANDS_FAILURE = `${APPEND_BRANDS}_FAILURE`;

export function fetchBrands(params) {
  return { type: FETCH_BRANDS, ...params };
}

export function appendBrands(params) {
  return { type: APPEND_BRANDS, ids: params.ids, per_page: 10 };
}
