export const FETCH_USAGES = 'FETCH_USAGES';
export const FETCH_USAGES_REQUEST = `${FETCH_USAGES}_REQUEST`;
export const FETCH_USAGES_SUCCESS = `${FETCH_USAGES}_SUCCESS`;
export const FETCH_USAGES_FAILURE = `${FETCH_USAGES}_FAILURE`;

export const APPEND_USAGES = 'APPEND_USAGES';
export const APPEND_USAGES_REQUEST = `${APPEND_USAGES}_REQUEST`;
export const APPEND_USAGES_SUCCESS = `${APPEND_USAGES}_SUCCESS`;
export const APPEND_USAGES_FAILURE = `${APPEND_USAGES}_FAILURE`;

export function fetchUsages(params) {
  return { type: FETCH_USAGES, ...params };
}

export function appendUsages(params) {
  return { type: APPEND_USAGES, usage_ids: params.ids, flat: 1, per_page: 500 };
}
