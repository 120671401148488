import React from 'react';
import PropTypes from 'prop-types';
import { t } from '../../i18n';

import ScoreTable from './ScoreTable';
import ScorePer from './ScorePer';
import {
  subscoreRules,
  multipleSubscoreRules,
  nutrientNameEx,
  scoreTooltip
} from './utils';

const healthLabelsOther = {
  B1: t('HEALTH_B1'),
  B2: t('HEALTH_B2'),
  C1: t('HEALTH_C1'),
  C2: t('HEALTH_C2')
};

// Table with details of a score outside the food pyramid
function ScoreTableOther({ health_report, detail, ...props }) {
  return (
    <div>
      <ScoreTable
        scoreLabels={healthLabelsOther}
        selectedScore={health_report.value}
        scores={
          detail ? detailScores(health_report) : basicScores(health_report)
        }
        {...props}
      />
      <ScorePer
        rules={multipleSubscoreRules(
          health_report,
          Object.keys(healthLabelsOther)
        )}
        style={{ width: 560 }}
      />
    </div>
  );
}

ScoreTableOther.propTypes = {
  health_report: PropTypes.object.isRequired,
  detail: PropTypes.bool
};

function basicScores(health_report) {
  // determine highest-scoring rule for each nutrient
  let nutrientScores = Object.keys(healthLabelsOther).reduce((o, score) => {
    subscoreRules(health_report, score).forEach(
      ({ id, value, rule, rule_input }) => {
        if (!o[id]) {
          o[id] = { id, label: nutrientNameEx(id), possibleScores: [] };
          // omit the tooltip in case of old scoring_objects
          o[id].tooltip = rule ? scoreTooltip(rule, rule_input) : null;
        }
        // gather scores for which there is a rule
        o[id].possibleScores.push(score);
        // if this is the first successful score, set it
        if (!o[id].score && value) {
          o[id].score = value;
        }
      }
    );
    return o;
  }, {});

  Object.values(nutrientScores).forEach((o) => {
    // Fallback to C2 score
    // @todo remove when fixed in API
    if (!o.score) {
      o.score = 'C2';
    }
    o.possibleScores.push('C2');
  });

  // @todo let possibleScores only show those scores which are present in the health_report
  return Object.values(nutrientScores);
}

function detailScores(health_report) {
  // assumes that there are no gaps in scores in front or in between
  //   this assumption holds for all health group rules at time of writing
  const nutrientScores = Object.keys(healthLabelsOther).reduce((o, score) => {
    subscoreRules(health_report, score).forEach(
      ({ id, value, rule, rule_input }) => {
        if (!o[id]) {
          const inputValue = (rule_input || {}).value;
          // first time we encounter a rule, get common data (should be the same for all)
          // @todo check assumption all of a nutrient's `rule_input.value` values are equal
          // @todo check assumption all of a nutrient's rules have enough in common
          o[id] = {
            id,
            label: nutrientNameEx(id),
            value: inputValue,
            boundaries: []
          };
          o[id].tooltip = scoreTooltip(rule, rule_input);
        }
        // then add this as a boundary on the linearscore line
        // assumes that boundaries for other scores are compared greater than
        // @todo check assumption that we only have nutrient_rule rules here
        o[id].boundaries.push({
          label: `${rule.value}${rule.unit}`,
          value: rule.value
        });
        // if this is the first successful score, set it
        if (!o[id].score && value) {
          o[id].score = value;
        }
      }
    );
    return o;
  }, {});

  Object.values(nutrientScores).forEach((o) => {
    // Fallback to C2 score
    // @todo remove when fixed in API
    if (!o.score) {
      o.score = 'C2';
    }
    // Add lower end of the scale
    o.boundaries.unshift({ label: '0', value: 0 });
    // Add upper end of the scale - @todo move overlap-avoidence to {ScoreScaleLinear}
    //   `|| 0` to make sure `NaN` still results in a maximum value
    const maxValue = Math.max(
      Math.max(...o.boundaries.map((b) => b.value)) * 2,
      o.value * 1.15 || 0
    );
    o.boundaries.push({ label: null, value: maxValue, marker: 'arrow' });
  });

  return Object.values(nutrientScores);
}

export default ScoreTableOther;
