import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import { t } from '../../i18n';

class ImageList extends Component {
  constructor() {
    super();
    this.state = { selectedIndex: null };
  }

  render() {
    const { images, title } = this.props;
    const { selectedIndex } = this.state;
    const nextIndex =
      selectedIndex !== null && (selectedIndex + 1) % images.length;
    const prevIndex =
      selectedIndex !== null &&
      (selectedIndex + images.length - 1) % images.length;

    return (
      <div>
        <Grid fluid>
          <Row>
            {images.map((image, index) => (
              <Col sm={4} key={index}>
                <a
                  href="#"
                  className="thumbnail"
                  onClick={() => this.setState({ selectedIndex: index })}>
                  <img
                    src={image.thumb}
                    alt=""
                    style={{ width: 50, height: 75, objectFit: 'scale-down' }}
                  />
                </a>
              </Col>
            ))}
          </Row>
        </Grid>
        {selectedIndex !== null && (
          <Lightbox
            imageTitle={title}
            mainSrc={
              images[selectedIndex].large || images[selectedIndex].medium
            }
            nextSrc={images[nextIndex].large || images[nextIndex].medium}
            prevSrc={images[prevIndex].large || images[prevIndex].medium}
            onCloseRequest={() => this.setState({ selectedIndex: null })}
            onMovePrevRequest={() =>
              this.setState({
                selectedIndex:
                  (selectedIndex + images.length - 1) % images.length
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                selectedIndex: (selectedIndex + 1) % images.length
              })
            }
            nextLabel={t('IMAGE_NEXT')}
            prevLabel={t('IMAGE_PREV')}
            zoomInLabel={t('IMAGE_ZOOM_IN')}
            zoomOutLabel={t('IMAGE_ZOOM_OUT')}
            closeLabel={t('IMAGE_CLOSE')}
          />
        )}
      </div>
    );
  }
}

ImageList.propTypes = {
  title: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      thumb: PropTypes.string.isRequired,
      medium: PropTypes.string.isRequired,
      large: PropTypes.string
    })
  )
};

export default ImageList;
