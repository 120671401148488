import {
  FETCH_USAGES_REQUEST,
  FETCH_USAGES_SUCCESS,
  FETCH_USAGES_FAILURE,
  APPEND_USAGES_REQUEST,
  APPEND_USAGES_SUCCESS,
  APPEND_USAGES_FAILURE
} from '../actions/usages';
import { idToString } from '../lib/helpers';

const initialState = {
  loading: false,
  items: [],
  total: 0
};

export default function usages(state = initialState, action) {
  switch (action.type) {
    case APPEND_USAGES_REQUEST:
    case FETCH_USAGES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_USAGES_SUCCESS:
      return {
        ...state,
        total: action.payload.total,
        items: action.payload.usages.map(idToString),
        loading: false
      };
    case APPEND_USAGES_SUCCESS:
      return {
        ...state,
        items: state.items.concat(
          action.payload.usages
            .filter((i) =>
              action.payload.params.usage_ids.includes(i.id.toString())
            )
            .map(idToString)
        ),
        loading: false
      };
    case APPEND_USAGES_FAILURE:
    case FETCH_USAGES_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
