import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { t } from '../../i18n';

import ScoreScaleLinear from './ScoreScaleLinear';
import ScoreMark from './ScoreMark';
import Tooltip from './ScoreTooltip';

/*
 * Personal health score analysis component
 *
 * This is basically an SVG table, with scoreLabels as column headers and
 * scores as rows. The label for each score item is the row header, and depending
 * on the score item properties, a suitable component is rendered.
 *
 * Possible score item formats:
 * - Circles (basic view)
 *     {id: 'p', label: 'Row header', score: 'B1', possibleScores: ['B1', B2']}
 * - Text value in the first column
 *     {id: 'q', label: 'Row header', score:  'A', value: 'x'}
 * - Linear scale
 *     {id: 'r', label: 'Row header', score: 'B2', value: 20, boundaries: [
 *       {label:   '0', value:  0},
 *       {label: '10g', value: 10},
 *       {label: '30g', value: 30},
 *       {label: '40g', value: 40},
 *       {label: null,  value: 80, marker: 'arrow'},
 *     ]}
 *
 * In all cases, `score` is used as a class to color the score, and sometimes to
 * indicate what the current score is (circles).
 *
 * For the linear scale, the `value` becomes a circle on the line with ticks
 * defined by `boundaries`. Note that ticks needn't be equidistant, the value
 * will just be linearly interpolated when placing the circle.
 *
 * Note that the `scoreLabels` property of this component is an object which
 * maps score keys ('A') to column headings ('Ja'). These will be the columns.
 *
 * Note that you don't need to wrap this in an SVG, that's already done. It is
 * meant to be used as any React component in a web application.
 *
 * Each score item can also have a `tooltip` attribute, which will be shown when
 * hovering over the indicator for the current score.
 *
 *
 * This could be setup much more cleanly by changing this component to a general
 * SVG table component. There's a bit of trickiness in (1) the linear scale
 * renderer needs to render multiple cells at once, and (2) the cell widths are
 * not equal (this could be made equal, provided that the gridlines and the
 * linear scale renderer are agreeing on where the boundaries are - perhaps a
 * shared d3 scale may come in handy here).
 *
 * Then this component wouldn't even know about the different renderers, but it
 * could provide row, header and cell components to nest.
 * [react-table-model](https://github.com/concur/react-table-model) may be
 * something to learn from here (like `propagatePropsToChildren`, perhaps).
 */

function ScoreTable({
  scoreLabels,
  scores,
  selectedScore,
  margin,
  rowHeight,
  cellWidth,
  labelWidth
}) {
  const [containerWidth, setWidth] = useState(300);
  const cellOffset = labelWidth + margin * 2;
  const nColumns = Object.keys(scoreLabels).length;
  const selectedOffset = Object.keys(scoreLabels).findIndex(
    (k) => k === selectedScore
  );
  const visibleWidth = cellOffset + nColumns * cellWidth + margin;
  const containerHeight = (scores.length + 1) * rowHeight;

  // Set container height
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // horizontal position of and in a cell: first and last cell are slightly larger to fit SortScaleLinear nicely
  function cellX(i, dx) {
    const start = i * cellWidth - margin * (i === 0 ? 1 : 0);
    const end = (i + 1) * cellWidth + margin * (i === nColumns - 1 ? 1 : 0);
    return start + (end - start) * dx;
  }

  return (
    <svg
      width={containerWidth}
      height={containerHeight}
      viewBox={`0 0 ${containerWidth} ${containerHeight}`}>
      {ScoreScaleLinear.defs}
      {/* Selected column */}
      {selectedOffset >= 0 ? (
        <g
          transform={`translate(${cellOffset},0)`}
          className="column-selection">
          <rect
            x={cellX(selectedOffset, 0)}
            y={0}
            width={cellX(selectedOffset, 1) - cellX(selectedOffset, 0)}
            height={containerHeight}
            style={styles.selection}
            className={`personal-health-score--${selectedScore}`}
          />
        </g>
      ) : null}
      {/* Column headers */}
      <g transform={`translate(${cellOffset},0)`} className="column-headers">
        {Object.values(scoreLabels).map((s, i) => (
          <text
            key={s}
            x={cellX(i, 0.5)}
            y={rowHeight / 2}
            dy="0.6ex"
            style={styles.scoreHeaderText}>
            {s}
          </text>
        ))}
      </g>
      {/* Column lines */}
      <g transform={`translate(${cellOffset},0)`} className="column-lines">
        {Object.keys(scoreLabels).map((_, i) =>
          i > 0 ? ( // don't paint header line, it is painted later at an offset
            <line
              key={i}
              x1={cellX(i, 0)}
              y1={0}
              x2={cellX(i, 0)}
              y2={containerHeight}
              style={styles.dividerLine}
            />
          ) : null
        )}
      </g>
      {/* Rule rows: line, label, detail */}
      {scores.map((score, i) => (
        <g
          key={score.id}
          transform={`translate(0, ${(i + 1) * rowHeight})`}
          className="row">
          {score.orPrev ? (
            <text x={margin * 2} y={0} dy="0.45ex" style={styles.orText}>
              {t('OR')}
            </text>
          ) : (
            <line
              x1={0}
              y1={0}
              x2={visibleWidth}
              y2={0}
              style={i > 0 ? styles.dividerLine : styles.headerDividerLine}
            />
          )}
          <Tooltip message={score.labelTooltip}>
            <g>
              <text
                y={rowHeight / 2}
                x={margin}
                dy="0.6ex"
                style={styles.headerText}>
                {score.label}
              </text>
              {score.labelTooltip && (
                <text
                  y={rowHeight / 2}
                  dy="0.7ex"
                  dx={labelWidth - margin}
                  className="glyphicon"
                  style={{ fill: '#bbb' }}>
                  &#xe086;
                </text>
              )}
            </g>
          </Tooltip>
          {/* Show details for score, depending on properties */}
          {score.boundaries ? (
            <ScoreScaleLinear
              boundaries={score.boundaries}
              value={score.value}
              tooltip={score.tooltip}
              valueProps={{
                className: `personal-health-score--${score.score}`,
                style: { fill: 'currentColor' }
              }}
              transform={`translate(${cellOffset}, ${margin})`}
              height={rowHeight - margin * 2}
              margin={margin}
              cellWidth={cellWidth}
            />
          ) : score.possibleScores ? (
            /* @todo move circles to separate component */
            <g
              transform={`translate(${cellOffset}, ${margin})`}
              className="score-scale-circles">
              {Object.keys(scoreLabels).map((k, i) =>
                score.possibleScores.includes(k) ? (
                  <Tooltip
                    key={i}
                    message={score.score === k ? score.tooltip : null}>
                    <circle
                      cx={cellX(i, 0.5)}
                      cy={(rowHeight - 2 * margin) / 2}
                      r={7}
                      className={`personal-health-score--${k}`}
                      style={
                        score.score === k
                          ? styles.circleSelected
                          : styles.circlePlaceholder
                      }
                    />
                  </Tooltip>
                ) : null
              )}
            </g>
          ) : (
            <ScoreMark
              value={score.value}
              tooltip={score.tooltip}
              valueProps={{
                className: `personal-health-score--${score.score}`,
                style: styles.markText
              }}
              transform={`translate(${cellOffset - margin}, ${margin})`}
              height={rowHeight - margin * 2}
              cellWidth={cellX(0, 1) - cellX(0, 0)}
            />
          )}
        </g>
      ))}
      {/* Column header line - drawn last to go on top */}
      <g transform={`translate(${cellOffset},0)`} className="column-lines">
        <line
          x1={-margin}
          y1={0}
          x2={-margin}
          y2={containerHeight}
          style={styles.headerDividerLine}
        />
      </g>
    </svg>
  );
}

ScoreTable.defaultProps = {
  margin: 12,
  rowHeight: 48,
  cellWidth: 80,
  labelWidth: 205 // width of longest label ('Geen transvet toegevoegd') + 2 * margin
};
ScoreTable.propTypes = {
  scores: PropTypes.arrayOf(PropTypes.object).isRequired,
  scoreLabels: PropTypes.object.isRequired,
  margin: PropTypes.number.isRequired,
  rowHeight: PropTypes.number.isRequired,
  cellWidth: PropTypes.number.isRequired,
  labelWidth: PropTypes.number.isRequired,
  selectedScore: PropTypes.string
};

const styles = {
  selection: {
    opacity: 0.1,
    fill: 'currentColor' // let CSS decide
  },
  headerText: {
    fontSize: 14,
    textAnchor: 'start'
  },
  orText: {
    fontSize: 12,
    textAnchor: 'start'
  },
  scoreHeaderText: {
    fontSize: 14,
    textAnchor: 'middle'
  },
  markText: {
    fontSize: 22,
    fill: 'currentColor' // let CSS decide
  },
  circleSelected: {
    fill: 'currentColor' // let CSS decide
  },
  circlePlaceholder: {
    fill: 'none',
    stroke: 'currentColor', // let CSS decide
    strokeWidth: 1.5,
    opacity: 0.2
  },
  dividerLine: {
    stroke: '#ddd',
    strokeWidth: 1
  },
  headerDividerLine: {
    stroke: '#333',
    strokeWidth: 1
  }
};

export default ScoreTable;

/*
 * Example scores array displaying the possible options for score items.
 *
 *    [
 *      {id: 'natrium2', label: 'Natrium (basic view)', score: 'B2', possibleScores: ['B1', 'B2', 'C1', 'C2'], tooltip: 'Natrium has B2'},
 *      {id: 'b22', label: 'Vitamine B12 (basic view)', score: 'B1', possibleScores: ['B1', 'B2'], tooltip: 'I love B[12]'},
 *      {id: 'natrium', label: 'Natrium', score: 'B2', value: 150, tooltip: '150mg', boundaries: [
 *        {label:   '0',   value:   0},
 *        {label: '100mg', value: 100},
 *        {label: '300mg', value: 300},
 *        {label: '500mg', value: 500},
 *        {label: null,    value: 700, marker: 'arrow'},
 *      ]},
 *      {id: 'sugar', label: 'Suiker', score: 'C1', value: 2.8, tooltip: '2.8g', boundaries: [
 *        {label: '0',  value: 0},
 *        {label: '1g', value: 1},
 *        {label: '2g', value: 2},
 *        {label: '3g', value: 3},
 *        {label: null, value: 5, marker: 'arrow'},
 *      ]},
 *      {id: 'fat_trans', label: 'Transvet', score: 'B2', value: 0.5, tooltip: '0.5g', boundaries: [
 *        {label: '0',    value: 0},
 *        {label: '0.1g', value: 0.1},
 *        {label: null,   value: 0.6, marker: 'arrow'},
 *      ]},
 *      {id: 'b12', label: 'Vitamine B12', score: 'B1', value: 0.3, tooltip: '0.3mcg', boundaries: [
 *        {label: null,      value: 0.48, marker: 'arrow'},
 *        {label: '0.24mcg', value: 0.24},
 *        {label: '0',       value: 0},
 *      ]},
 *      {id: 'iron', label: 'IJzer', score: null, value: null, tooltip: null, boundaries: [
 *        {label: null,    value: 2.2, marker: 'arrow'},
 *        {label: '0.8mg', value: 0.8},
 *        {label: '0',     value: 0},
 *      ]},
 *      {id: 'protein_vs_energy', label: 'Eiwit vs. energie', score: 'B2', value: 0, tooltip: '0%', boundaries: [
 *        {label: null,  value: 24, marker: 'arrow'},
 *        {label: '12%', value: 12},
 *        {label: 0,     value:  0},
 *      ]},
 *      {id: 'added_salt',      label: 'Geen zout toegevoegd',     score: 'A',  value: '✔', tooltip: 'Geen van de ingrediënten is zout'},
 *      {id: 'added_fat_trans', label: 'Geen transvet toegevoegd', score: null, value: '⨯', tooltip: 'Geen van de ingrediënten is (vnl.) transvet'},
 *    ];
 */
