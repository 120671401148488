import I18n from 'i18n-js';
import store from '../store';
import en from './en';
import nl from './nl';

const state = store.getState();

I18n.fallbacks = true;
I18n.translations = { en, nl };
I18n.missingTranslationPrefix = 'EE: ';
I18n.missingBehaviour = 'guess';
I18n.locale = state.ui.lang;

export const supported = ['nl', 'en'];

export default I18n;

export function t(key, obj = {}) {
  return I18n.t(key, obj);
}
