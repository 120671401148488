import React from 'react';
import { Grid } from 'react-bootstrap';
import { t } from '../../i18n';

function NotFound(props) {
  return (
    <Grid>
      <em>{t('PAGE_NOT_FOUND')}</em>
    </Grid>
  );
}

export default NotFound;
