import React from 'react';
import PropTypes from 'prop-types';
import { NUTRISCORES } from '../../constants';

import Filter from './Filter';

const items = NUTRISCORES.map((s) => ({ name: s, id: s }));

export default function Nutriscore(props) {
  return (
    <Filter
      value={props.value}
      title="NUTRISCORE"
      items={items}
      total={items.length}
      attr="nutriscores"
      searchable={false}
    />
  );
}

Nutriscore.propTypes = {
  value: PropTypes.array
};
