import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { Route } from 'react-router';
import moment from '../../lib/moment-with-locales';

import { t } from '../../i18n';
import { fetchProduct, fetchScoringReport } from '../../actions/products';

import Loader from '../../components/Loader';
import Unvalidated from '../../components/Unvalidated';
import ProductDetailHeader from '../../components/ProductDetailHeader';
import ProductScores from '../../components/ProductScores';
import ProductInfo from '../../components/ProductInfo';
import NotFound from '../../components/NotFound';
import HealthImprovement from '../../components/HealthImprovement';
import ImageList from '../../components/ImageList';

class ProductDetail extends Component {
  componentDidMount() {
    this.props.dispatch(fetchProduct({ ids: this.props.id }));
  }

  componentDidUpdate(prev, prevState) {
    const { selected, admin, owned_brand_ids } = this.props;
    if (selected && (admin || owned_brand_ids.includes(selected.brand_id))) {
      if (
        (!prev.selected && selected) ||
        (prev.selected && selected && prev.selected.id !== selected.id)
      ) {
        this.props.dispatch(fetchScoringReport(selected.id));
      }
    }
  }

  render() {
    const { loading, selected: product, owned_brand_ids } = this.props;

    if (!product) {
      return loading ? (
        <Grid>
          <br />
          <Loader loading={loading} />
        </Grid>
      ) : (
        <Route component={NotFound} />
      );
    }

    return (
      <Grid className="product-detail-container">
        <br />
        <Row>
          <Col sm={9}>
            <ProductDetailHeader
              owned_brand_ids={owned_brand_ids}
              {...product}
            />
            <Tabs id="product-detail-tabs">
              <Tab eventKey={1} title={t('LABEL')}>
                <ProductInfo {...product} />
              </Tab>
              <Tab
                eventKey={2}
                title={<span>{t('HEALTH')}</span>}
                disabled={!product.scoring_report}
                mountOnEnter>
                {product.scoring_report && (
                  <HealthImprovement
                    product={product}
                    scoring_report={product.scoring_report}
                  />
                )}
              </Tab>
            </Tabs>
          </Col>
          <Col sm={3}>
            <ProductScores {...product.columns} />
            <hr />
            <div className="age">
              <p>
                <strong>{t('AGE')}</strong>
              </p>
              <p>{moment(product.confirmed_at).format('LL')}</p>
            </div>
            {product.unvalidated_data && [
              <hr key={1} />,
              <Unvalidated expanded key={2} />
            ]}
            {product.images.length > 0 && [
              <hr key={1} />,
              <ImageList images={product.images} title={product.name} key={2} />
            ]}
          </Col>
        </Row>
      </Grid>
    );
  }
}

ProductDetail.propTypes = {
  dispatch: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  selected: PropTypes.object,
  owned_brand_ids: PropTypes.array,
  admin: PropTypes.bool.isRequired
};

function select(state, props) {
  return {
    ...state.products,
    owned_brand_ids: state.user.owned_brands.map((b) => b.id),
    admin: state.user.plan && state.user.plan.key === 'admin',
    id: parseInt(props.match.params.id, 10)
  };
}

export default connect(select)(ProductDetail);
