import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, Route } from 'react-router-dom';

import { t } from '../../i18n';
import { appName } from '../../config';
import SearchBox from '../SearchBox';

import {
  Navbar,
  Nav,
  NavItem,
  Image,
  NavDropdown,
  MenuItem,
  FormGroup
} from 'react-bootstrap';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  render() {
    const { loggedIn, email, onLogout } = this.props;

    return (
      <Navbar collapseOnSelect inverse>
        <Navbar.Header>
          <Navbar.Brand>
            <Link to="/" className="navbar-brand">
              <Image src="/images/logo.svg" responsive alt={appName} />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          {!loggedIn && (
            <Nav pullRight>
              <ListItemLink to="/login">{t('LOGIN')}</ListItemLink>
            </Nav>
          )}
          {loggedIn && (
            <Navbar.Form pullLeft>
              <FormGroup>
                <SearchBox placeholder={t('SEARCH_PLACEHOLDER')} />
              </FormGroup>
            </Navbar.Form>
          )}
          {loggedIn && (
            <Nav pullRight>
              <ListItemLink to="/comparisons">{t('COMPARISONS')}</ListItemLink>
              <ListItemLink to="/products">{t('PRODUCTS')}</ListItemLink>
              <NavDropdown
                title={t('ACCOUNT')}
                id="account"
                open={this.state.open}
                onToggle={(open) => this.onToggle(open)}>
                <ListItemLink
                  to="/brands"
                  onClick={(e) => this.toggleDropdown()}>
                  {t('MY_BRANDS')}
                </ListItemLink>
                <ListItemLink
                  to="/profile"
                  onClick={(e) => this.toggleDropdown()}>
                  {t('PROFILE')}
                </ListItemLink>
                <MenuItem divider />
                <NavItem onSelect={(e) => onLogout()}>
                  {t('LOGOUT')}
                  <br />
                  <small className="text-muted">{email}</small>
                </NavItem>
              </NavDropdown>
            </Nav>
          )}
        </Navbar.Collapse>
      </Navbar>
    );
  }

  onToggle(open) {
    this.setState({ open });
  }

  toggleDropdown() {
    this.setState({ open: !this.state.open });
  }
}

Header.propTypes = {
  loggedIn: PropTypes.bool,
  email: PropTypes.string,
  onLogout: PropTypes.func.isRequired
};

Header.defaultProps = {
  loggedIn: false,
  email: '',
  onLogout: () => {}
};

// https://github.com/react-bootstrap/react-router-bootstrap/issues/186#issuecomment-285944359
function ListItemLink({ to, children, activeKey, activeHref, ...rest }) {
  return (
    <Route
      path={to}
      children={({ match }) => (
        <li role="presentation">
          <Link to={to} {...rest}>
            {children}
          </Link>
        </li>
      )}
    />
  );
}

export default Header;
