import React from 'react';
import PropTypes from 'prop-types';

import Filter from './Filter';
import { fetchUsages, appendUsages } from '../../actions/usages';

export default function Usages(props) {
  return (
    <Filter
      value={props.value}
      title="USAGES"
      searchKey="usages[q]"
      fetcher={fetchUsages}
      appender={appendUsages}
      stateName="usages"
      attr="usage_ids"
    />
  );
}

Usages.propTypes = {
  value: PropTypes.array
};
