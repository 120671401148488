import React from 'react';
import PropTypes from 'prop-types';
import { Media, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';

import BrandLabel from '../BrandLabel';

import { t } from '../../i18n';

const certificateTooltip = ({ id, name }) => (
  <Tooltip placement="top" className="in" id={`tooltip-${id}`}>
    {name}
  </Tooltip>
);

function ProductDetailHeader({
  name,
  image,
  barcodes,
  brand_name,
  brand_id,
  certificates
}) {
  return (
    <Media>
      <Media.Left>
        <Image
          src={image.medium}
          alt={name}
          style={{ maxWidth: 100, maxHeight: '22ex', objectFit: 'scale-down' }}
        />
      </Media.Left>
      <Media.Body>
        <Media.Heading className="h2">
          <p>{name}</p>
          <BrandLabel brand_id={brand_id}>{brand_name}</BrandLabel>
        </Media.Heading>
        {!!barcodes.length && (
          <p className="text-muted">
            <i className="glyphicon glyphicon-barcode"></i>{' '}
            {barcodes.join(', ')}
          </p>
        )}
        <p className="flex-row middle-xs text-muted">
          <i className="glyphicon glyphicon-certificate"></i>
          &nbsp;
          {certificates.map((c, i) => (
            <OverlayTrigger
              key={i}
              placement="top"
              overlay={certificateTooltip(c)}>
              <Image
                src={(c.image_url || {}).thumb || '/images/placeholder.svg'}
                alt={c.name}
                style={{ paddingRight: 5, height: '3.5ex' }}
              />
            </OverlayTrigger>
          ))}
          {!certificates.length && <em>{t('NO_CERTIFICATES')}</em>}
        </p>
      </Media.Body>
    </Media>
  );
}

ProductDetailHeader.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.shape({
    medium: PropTypes.string.isRequired
  }),
  barcodes: PropTypes.array,
  brand_name: PropTypes.string,
  brand_id: PropTypes.number,
  certificates: PropTypes.array
};

ProductDetailHeader.defaultProps = {
  image: {
    medium: '/images/placeholder.svg'
  },
  barcodes: [],
  certificates: []
};

export default ProductDetailHeader;
