export const FETCH_RETAILERS = 'FETCH_RETAILERS';
export const FETCH_RETAILERS_REQUEST = `${FETCH_RETAILERS}_REQUEST`;
export const FETCH_RETAILERS_SUCCESS = `${FETCH_RETAILERS}_SUCCESS`;
export const FETCH_RETAILERS_FAILURE = `${FETCH_RETAILERS}_FAILURE`;

export const APPEND_RETAILERS = 'APPEND_RETAILERS';
export const APPEND_RETAILERS_REQUEST = `${APPEND_RETAILERS}_REQUEST`;
export const APPEND_RETAILERS_SUCCESS = `${APPEND_RETAILERS}_SUCCESS`;
export const APPEND_RETAILERS_FAILURE = `${APPEND_RETAILERS}_FAILURE`;

export function fetchRetailers(params) {
  return { type: FETCH_RETAILERS, ...params };
}

export function appendRetailers(params) {
  return { type: APPEND_RETAILERS, retailer_ids: params.ids, per_page: 500 };
}
