import {
  FETCH_NUTRIENTS_REQUEST,
  FETCH_NUTRIENTS_SUCCESS,
  FETCH_NUTRIENTS_FAILURE
} from '../actions/nutrients';

import { NUTRIENT_UNITS } from '../constants';

const initialState = {
  loading: false,
  items: [],
  total: 0
};

export default function nutrients(state = initialState, action) {
  switch (action.type) {
    case FETCH_NUTRIENTS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_NUTRIENTS_SUCCESS:
      return {
        ...state,
        total: action.payload.total,
        items: action.payload.nutrients.map(productNutrient),
        loading: false
      };
    case FETCH_NUTRIENTS_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}

function productNutrient(nutrient) {
  if (nutrient.unit) return nutrient;
  nutrient.unit = NUTRIENT_UNITS[nutrient.code] || 'g/100g';
  return nutrient;
}
