import {
  FETCH_COMPARISONS_REQUEST,
  FETCH_COMPARISONS_SUCCESS,
  FETCH_COMPARISONS_FAILURE,
  CREATE_COMPARISON_REQUEST,
  CREATE_COMPARISON_SUCCESS,
  CREATE_COMPARISON_FAILURE,
  UPDATE_COMPARISON_REQUEST,
  UPDATE_COMPARISON_SUCCESS,
  UPDATE_COMPARISON_FAILURE,
  REMOVE_COMPARISON_REQUEST,
  REMOVE_COMPARISON_SUCCESS,
  REMOVE_COMPARISON_FAILURE
} from '../actions/comparisons';

const initialState = {
  loading: false,
  created: false,
  updated: false,
  items: [],
  total: 0,
  error: ''
};

export default function comparisons(state = initialState, action) {
  switch (action.type) {
    case CREATE_COMPARISON_REQUEST:
    case UPDATE_COMPARISON_REQUEST:
    case REMOVE_COMPARISON_REQUEST:
    case FETCH_COMPARISONS_REQUEST:
      return {
        ...state,
        error: '',
        loading: true,
        created: false,
        updated: false
      };
    case REMOVE_COMPARISON_SUCCESS:
    case FETCH_COMPARISONS_SUCCESS:
      return {
        ...state,
        total: action.payload.length,
        items: action.payload,
        error: '',
        loading: false,
        created: false,
        updated: false
      };
    case CREATE_COMPARISON_SUCCESS:
      return {
        ...state,
        total: action.payload.length,
        items: action.payload,
        error: '',
        loading: false,
        created: true
      };
    case UPDATE_COMPARISON_SUCCESS:
      return {
        ...state,
        total: action.payload.length,
        items: action.payload,
        error: '',
        loading: false,
        updated: true
      };
    case CREATE_COMPARISON_FAILURE:
    case UPDATE_COMPARISON_FAILURE:
    case REMOVE_COMPARISON_FAILURE:
    case FETCH_COMPARISONS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
        created: false,
        updated: false
      };
    default:
      return state;
  }
}
