const nl = {
  LOADING: 'Laden',
  SIGN_UP: 'Sign up',
  LOGIN: 'Inloggen',
  LOGOUT: 'Uitloggen',
  EMAIL: 'Email',
  PASSWORD: 'Wachtwoord',
  FORGOT_PASSWORD: 'Wachtwoord vergeten',
  SUBMIT: 'Submit',
  PRODUCTS: 'Producten',
  BRANDS: 'Merken',
  BRAND: 'Merk',
  USAGES: 'Productsoort',
  USAGE: 'Productsoort',
  CERTIFICATES: 'Keurmerken',
  RETAILERS: 'Retailers',
  CATEGORY: 'Categorie',
  SUPPLIED: 'Data aangeleverd',
  SUSTAINABILITY: 'Duurzaamheid',
  HEALTH: 'Gezondheid',
  NURTISCORE: 'Nutriscore',
  HEALTH_SCORE: 'Gezondheidsscore',
  COMPARISON_GROUPS: 'Comparison groups',
  INGREDIENTS: 'Ingrediënten',
  NUTRIENTS: 'Voedingswaarden',
  ACCOUNT: 'Account',
  PROFILE: 'Profiel',
  HELP: 'Help',
  SAVE: 'Opslaan',
  NAME: 'Naam',
  COMPANY_NAME: 'Bedrijf',
  AGE: 'Laatste update',
  SOURCES: 'Bronnen',
  SOURCE: 'Bron',
  POSITION: 'Functie',
  DEPARTMENT: 'Afdeling',
  ADDRESS: 'Adres',
  ZIPCODE: 'Postcode',
  CITY: 'Stad',
  PHONE: 'Telefoon',
  ABOUT: 'Over',
  VALID_UNTIL: 'Toegang tot',

  HEALTH_A: 'Ja',
  HEALTH_B1: 'Redelijk',
  HEALTH_B1_LOGO: 'Redelijk',
  HEALTH_B2: 'Matig',
  HEALTH_C1: 'Zeer matig',
  HEALTH_C2: 'Minst',

  DETAILS: 'details',
  HEALTH_IMPROVEMENT_TITLE: 'Verbeterpotentieel gezondheid',
  HEALTH_IMPROVEMENT_ABSENT: 'Dit product heeft nog geen gezondheidsscore.',
  HEALTH_IMPROVEMENT_PENDING:
    'Sorry, dit product heeft nog geen gezondheidsanalyse beschikbaar.',
  HEALTH_IMPROVEMENT_MISSING:
    'Dit product kon geen gezondheidsscore gegeven worden.',
  HEALTH_IMPROVEMENT_STATIC:
    'Producten in deze gezondheidsgroep krijgen altijd deze gezondheidsbeoordeling.',
  HEALTH_IMPROVEMENT_IN_SCHIJF_BODY_1: '{{product}} valt',
  HEALTH_IMPROVEMENT_IN_SCHIJF_BODY_2: 'binnen de Schijf van Vijf (SvV)',
  HEALTH_IMPROVEMENT_IN_SCHIJF_BODY_3:
    ' en heeft daarmee reeds de hoogste gezondheidsbeoordeling.',
  HEALTH_IMPROVEMENT_IN_SCHIJF_HEADING:
    'Er wordt voldaan aan alle onderstaande normen:',
  HEALTH_IMPROVEMENT_IN_SCHIJF_OR: 'of aan alle volgende normen:',
  HEALTH_IMPROVEMENT_UIT_SCHIJF_BODY_1: '{{product}} valt',
  HEALTH_IMPROVEMENT_UIT_SCHIJF_BODY_2: 'buiten de Schijf van Vijf',
  HEALTH_IMPROVEMENT_UIT_SCHIJF_BODY_3: ' en heeft krijgt een',
  HEALTH_IMPROVEMENT_UIT_SCHIJF_BODY_4: ' op gezondheid.',
  HEALTH_IMPROVEMENT_UIT_SCHIJF_HEADING:
    'Om binnen de Schijf van Vijf (SvV) te vallen, moet er voldaan worden aan alle onderstaande normen:',
  HEALTH_IMPROVEMENT_NOOIT_SCHIJF_HEADING:
    'Producten in deze gezondheidsgroep vallen altijd buiten de Schijf van Vijf.',

  SEARCH_PLACEHOLDER: 'Zoek product of barcode',
  COMPARISON_NAME_PLACEHOLDER: 'Geef de vergelijking een naam',

  MY_BRANDS: 'Mijn merken',
  MY_PRODUCTS: 'Mijn producten',
  VIEW: 'Bekijk',
  CONTACT: 'Contact',
  SCORES: 'Scores',
  IMPACT: 'Impact',

  SCORE_SOCIAL: 'Mensenrechten',
  SCORE_ANIMALS: 'Dierenwelzijn',
  SCORE_ENVIRONMENT: 'Milieu',
  SCORE_HEALTH: 'Gezond',
  SCORE_SUSTAINABILITY: 'Duurzaamheid',

  NUTRIENT_CALCIUM: 'Calcium',
  NUTRIENT_PROTEIN: 'Eiwitten',
  NUTRIENT_ENERGY: 'Energie (kJ)',
  NUTRIENT_ENERGY_THERMAL: 'Energie (kcal)',
  NUTRIENT_ENERGY_ANY: 'Energie',
  NUTRIENT_FAT_MONOUNSATURATED: 'Enkelvoudig onverzadigd vet',
  NUTRIENT_IRON: 'IJzer',
  NUTRIENT_CARBOHYDRATES: 'Koolhydraten / Glucides',
  NUTRIENT_LACTOSE: 'Lactose',
  NUTRIENT_FAT_POLYUNSATURATED: 'Meervoudig onverzadigd vet',
  NUTRIENT_NATRIUM: 'Natrium',
  NUTRIENT_SUGAR: 'Suiker',
  NUTRIENT_SUGAR_ANY: 'Suiker',
  NUTRIENT_SUGAR_NO_FRUITJUICE: 'Suiker',
  NUTRIENT_SUGAR_OR_FRUITJUICE: 'Suiker',
  NUTRIENT_FAT_TRANS: 'Transvet',
  NUTRIENT_FAT_SATURATED: 'Verzadigd vet',
  NUTRIENT_FAT_TOTAL: 'Vetten',
  NUTRIENT_FIBER: 'Vezels',
  NUTRIENT_A: 'Vitamine A',
  NUTRIENT_B1: 'Vitamine B1',
  NUTRIENT_B12: 'Vitamine B12',
  NUTRIENT_C: 'Vitamine C',
  NUTRIENT_SALT: 'Zout',
  NO_ADDED_NUTRIENT: 'Geen {{nutrient}} toegevoegd',
  NO_ADDED_UNHEALTHY: 'Alle ingrediënten in SvV',
  NUTRIENT_SUGAR_OR_NO_ADDED_SUGAR: 'Suiker',
  NUTRIENT_B1_OR_B12: 'Vitamine B1 of B12',
  VALUE_UNKNOWN: 'waarde onbekend',

  NUTRIENTS_PREPARED: 'voor het bereide product',
  NUTRIENTS_UNPREPARED: 'voor het onbereide product',

  VALUE_PER_PORTION: '{{value}}{{unit}} per portie',
  VALUE_PER_PER: '{{value}}{{unit}} per {{per}',
  PER_PORTION_OF: 'per portie van {{portion}}',
  UNHEALTHY_EXCEPT_SS_NOTE: 'suiker en zout toegestaan',
  UNHEALTHY_EXCEPT_FRUITJUICE_NOTE: 'fruitsap toegestaan',
  OR: 'of',

  SHOW_OWNED_PRODUCTS_ONLY: 'Alleen mijn merken tonen',
  NO_RESULTS: 'Geen resultaten',
  SELECT_OR_SEARCH: 'Kies of zoek',
  SELECT: 'Kies',
  NO_CERTIFICATES: 'Geen keurmerken',
  PRODUCER: 'Producent',
  SUPERMARKET: 'Supermarkten',
  REFRIGERATION_TYPE: 'Koeling',
  PACKAGING: 'Verpakking',
  PAGE_NOT_FOUND: 'Pagina niet gevonden',
  FORBIDDEN: 'Niet toegestaan',
  CLEAR_ALL_FILTERS: 'Filters en zoektermen wissen',

  CREATE_NEW_COMPARISON: 'Nieuwe vergelijking',
  SELECTED: 'Geselecteerd',
  ADD_TO_COMPARISON_LIST: 'Toevoegen aan vergelijking',
  COMPARISONS: 'Vergelijkingen',
  EDIT_COMPARISON: 'Bewerk vergelijking',
  ADD_PRODUCT_TO_COMPARISON: 'Product toevoegen',
  NO_COMPARISON_PRODUCTS: 'Deze vergelijking bevat geen producten.',

  MSG_PROFILE_UPDATED: 'Pofiel aangepast',
  MSG_COMPARISON_CREATED: 'Vergelijking aangemaakt',
  MSG_COMPARISON_UPDATED: 'Vergelijking aangepast',
  MSG_RESET_PASSWORD_SENT: 'Er is een email met instructies verstuurd.',

  LANG_EN: 'Engels',
  LANG_NL: 'Nederlands',

  LABEL: 'Etiket',

  SHOW_PRODUCT_AGE: 'Toon actualiteit data',

  LESS_THAN_N_MONTH: {
    one: 'Minder dan 1 maand geleden',
    other: 'Minder dan %{count} maanden geleden'
  },

  PACKAGE_SIZE: 'Verpakkingsgrootte',
  EXPORT_CSV: 'Export CSV',
  MSG_MUST_BE_INVITED: 'Uitnodiging vereist.',
  I_ACCEPT: 'Ik accepteer de',
  TERMS_CONDITIONS: 'voorwaarden',
  SIGN_UP_SUCCESS: 'U heeft nu een account voor QMi Dashboard',
  SIGN_UP_FAILURE: 'Er is een probleem opgetreden bij het inschrijven.',
  MSG_CONFIRM_EXPORT:
    'De selectie van producten die u gaat downloaden is vrij groot. Het downloaden kan tot %{count} minuten duren. Weet u zeker dat u wilt doorgaan?',
  SUPPORT: 'Support',
  SEARCH: 'Zoek',
  PLATFORM_ISSUES:
    'Er zijn problemen met het platform. Probeer het later nog eens.',
  SEARCHING: 'Bezig.',
  UNVALIDATED_INFO:
    'De informatie over dit product is recent gewijzigd en is nog niet gecontroleerd op eventuele fouten.',
  BETA_LABEL: 'beta',
  BETA_INFO:
    'Dit onderdeel is beschikbaar als preview en nog niet voldoende getest.',

  IMAGE_NEXT: 'Volgende foto',
  IMAGE_PREV: 'Vorige foto',
  IMAGE_ZOOM_IN: 'Inzoomen',
  IMAGE_ZOOM_OUT: 'Uitzoomen',
  IMAGE_CLOSE: 'Foto sluiten',

  METABASE_NOT_AUTHORISED:
    'It looks like you do not have the required permissions to view this page',

  FILTER_SHOWING_X_ITEMS: 'Toont {{n}} items, typ om te zoeken',
  FILTER_NO_RESULTS: 'Geen resultaten, probeer een andere zoekopdracht'
};

export default nl;
