import AddEdit from './AddEdit';
import Existing from './Existing';
import Nutrient from './Nutrient';
import ProductList from './ProductList';

export default {
  AddEdit,
  Existing,
  Nutrient,
  ProductList
};
