import {
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAILURE,
  FETCH_PRODUCT_REQUEST,
  FETCH_PRODUCT_SUCCESS,
  FETCH_PRODUCT_FAILURE,
  FETCH_SCORING_REPORT_REQUEST,
  FETCH_SCORING_REPORT_SUCCESS,
  FETCH_SCORING_REPORT_FAILURE
} from '../actions/products';
import product from './product';

const initialState = {
  loading: false,
  selected: null,
  items: [],
  total: 0,
  default_sort_key: 'name',
  default_sort_order: 'asc'
};

export default function products(state = initialState, action) {
  switch (action.type) {
    case FETCH_PRODUCTS_REQUEST:
      return {
        ...state,
        selected: null,
        items: [],
        total: 0,
        loading: true
      };
    case FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        total: action.payload.total,
        items: action.payload.products.map(product),
        loading: false
      };
    case FETCH_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false
      };
    case FETCH_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
        selected: state.items.reduce(
          (init, item) => (item.id == action.ids ? item : init),
          null
        )
      };
    case FETCH_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        selected: {
          ...state.selected,
          ...action.payload.products.map(product)[0]
        }
      };
    case FETCH_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        selected: null
      };
    case FETCH_SCORING_REPORT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_SCORING_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        selected: {
          ...state.selected,
          scoring_report: action.payload.scoring_report
        }
      };
    case FETCH_SCORING_REPORT_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
