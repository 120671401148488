import moment from 'moment';
import LocalStore from 'store';
import { NOTIFY, CHANGE_LANGUAGE } from '../actions/ui';

const lang = LocalStore.get('lang') || 'nl';
const initialState = { lang };
moment.locale(lang);

export default function items(state = initialState, action) {
  switch (action.type) {
    case NOTIFY:
      return state;
    case CHANGE_LANGUAGE:
      return {
        ...state,
        lang: action.lang
      };
    default:
      return state;
  }
}
