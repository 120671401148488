import React from 'react';
import PropTypes from 'prop-types';
import {
  createUltimatePagination,
  ITEM_TYPES
} from 'react-ultimate-pagination';
import { addQuery } from '../../lib/query-params';
import { PER_PAGE } from '../../constants';

// https://github.com/ultimate-pagination/react-ultimate-pagination

const WrapperComponent = ({ children }) => (
  <ul className="pagination">{children}</ul>
);

const withPreventDefault = (fn) => (event) => {
  event.preventDefault();
  fn();
};

const Page = ({ value, isActive, onClick }) => (
  <li className={isActive ? 'page-item active' : 'page-item'}>
    <a className="page-link" href="#" onClick={withPreventDefault(onClick)}>
      {value}
    </a>
  </li>
);

const Ellipsis = ({ onClick }) => (
  <li className="page-item">
    <a className="page-link" href="#" onClick={withPreventDefault(onClick)}>
      ...
    </a>
  </li>
);

const FirstPageLink = ({ onClick }) => (
  <li className="page-item">
    <a className="page-link" href="#" onClick={withPreventDefault(onClick)}>
      &laquo;
    </a>
  </li>
);

const PreviousPageLink = ({ onClick }) => (
  <li className="page-item">
    <a className="page-link" href="#" onClick={withPreventDefault(onClick)}>
      &lsaquo;
    </a>
  </li>
);

const NextPageLink = ({ onClick }) => (
  <li className="page-item">
    <a className="page-link" href="#" onClick={withPreventDefault(onClick)}>
      &rsaquo;
    </a>
  </li>
);

const LastPageLink = ({ onClick }) => (
  <li className="page-item">
    <a className="page-link" href="#" onClick={withPreventDefault(onClick)}>
      &raquo;
    </a>
  </li>
);

const itemTypeToComponent = {
  [ITEM_TYPES.PAGE]: Page,
  [ITEM_TYPES.ELLIPSIS]: Ellipsis,
  [ITEM_TYPES.FIRST_PAGE_LINK]: FirstPageLink,
  [ITEM_TYPES.PREVIOUS_PAGE_LINK]: PreviousPageLink,
  [ITEM_TYPES.NEXT_PAGE_LINK]: NextPageLink,
  [ITEM_TYPES.LAST_PAGE_LINK]: LastPageLink
};

const UltimatePagination = createUltimatePagination({
  itemTypeToComponent,
  WrapperComponent
});

function Paginate({ current, total, perPage, onSelect }) {
  if (!perPage) perPage = PER_PAGE;
  if (!total) return null;
  return (
    <div className="text-center">
      <UltimatePagination
        currentPage={parseInt(current)}
        totalPages={Math.ceil(total / perPage) || 1}
        onChange={onSelect}
      />
    </div>
  );
}

Paginate.propTypes = {
  current: PropTypes.number,
  total: PropTypes.number,
  perPage: PropTypes.number,
  onSelect: PropTypes.func.isRequired
};

Paginate.defaultProps = {
  onSelect: (page) => addQuery({ page })
};

export default Paginate;
