import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Table, Media, Image, Checkbox } from 'react-bootstrap';
import moment from '../../lib/moment-with-locales';

import BrandLabel from '../BrandLabel';
import Nutriscore from '../Nutriscore';
import HealthScore from '../HealthScore';
import Unvalidated from '../Unvalidated';

import { t } from '../../i18n';
import { NUTRIENT_UNITS } from '../../constants';
import { addQuery } from '../../lib/query-params';
import { sortClass, sort } from '../../lib/sort';

function ProductsList(props) {
  const { items, columns, onSelect, showAge, showNutriscore, showHealthScore } =
    props;
  return (
    <Table hover responsive className="sortable">
      <thead>
        <tr>
          {onSelect && (
            <th width="40px">
              {/* <Checkbox inline onChange={e => onSelect(null)} /> */}
            </th>
          )}
          <th
            width="35%"
            className={sortClass('name', props.sort)}
            onClick={(e) => sort('name', props.sort, 'asc')}>
            {t('NAME')}
          </th>
          {columns.map((col, index) => (
            <th
              key={index}
              className={sortClass(`nutrient_${col}`, props.sort)}
              onClick={(e) => sort(`nutrient_${col}`, props.sort)}>
              {t(`NUTRIENT_${col.toUpperCase()}`)}
              <br />
              <small>({NUTRIENT_UNITS[col] || 'g/100g'})</small>
            </th>
          ))}
          {showNutriscore && (
            <th
              width="13%"
              className={sortClass('nutriscore', props.sort, true)}
              onClick={(e) => sort('nutriscore', props.sort, 'asc')}>
              {t('NUTRISCORE')}
            </th>
          )}
          {showHealthScore && (
            <th
              width="13%"
              className={sortClass('personal_health_score', props.sort, true)}
              onClick={(e) => sort('personal_health_score', props.sort, 'asc')}>
              {t('HEALTH_SCORE')}
            </th>
          )}
          {showAge && (
            <th
              width="13%"
              className={sortClass('confirmed_at', props.sort, true)}
              onClick={(e) => sort('confirmed_at', props.sort, 'asc')}>
              {t('AGE')}
              <br />
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => (
          <tr key={index}>
            {onSelect && (
              <td width="40px">
                <Checkbox
                  inline
                  checked={item.selected}
                  onChange={(e) => onSelect(item)}
                />
              </td>
            )}
            <td>
              <Media>
                <Media.Left align="middle">
                  <Image
                    src={item.image.thumb || '/images/placeholder.svg'}
                    width={40}
                    style={{ maxHeight: 60, objectFit: 'scale-down' }}
                  />
                </Media.Left>
                <Media.Body>
                  <Link to={`/products/${item.id}`}>{item.name}</Link> &nbsp;
                  {item.unvalidated_data && <Unvalidated />}
                  <div className="product-meta text-muted">
                    {item.barcode && (
                      <span>
                        <i className="glyphicon glyphicon-barcode"></i>{' '}
                        <small>{item.barcode}</small>
                      </span>
                    )}{' '}
                    <a onClick={onFilter({ brand_ids: [item.brand_id] })}>
                      <BrandLabel brand_id={item.brand_id}>
                        {item.brand_name}
                      </BrandLabel>
                    </a>{' '}
                    <a onClick={onFilter({ usage_ids: [item.usage.id] })}>
                      <small>{item.usage.name}</small>
                    </a>
                  </div>
                </Media.Body>
              </Media>
            </td>
            {columns.map((col, index) => (
              <td key={index}>
                {item.columns[`${col}_value`]}
                {item.columns[`${col}_unit`]}
              </td>
            ))}
            {showNutriscore && (
              <td>
                <Nutriscore score={item.columns.nutriscore} />
              </td>
            )}
            {showHealthScore && (
              <td>
                <HealthScore score={item.columns.health_score} />
              </td>
            )}
            {showAge && (
              <td>
                <small className="text-muted">
                  {moment(item.confirmed_at).format('LL')}
                </small>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

ProductsList.propTypes = {
  items: PropTypes.array.isRequired,
  columns: PropTypes.array,
  onSelect: PropTypes.func,
  sort: PropTypes.string,
  showAge: PropTypes.bool,
  showNutriscore: PropTypes.bool,
  showHealthScore: PropTypes.bool
};

ProductsList.defaultProps = {
  items: [],
  columns: ['salt', 'sugar', 'fat_saturated', 'energy'],
  sort: '',
  showAge: false,
  showNutriscore: false,
  showHealthScore: false
};

function onFilter(obj) {
  return (e) => {
    e.preventDefault();
    obj.page = 1;
    addQuery(obj);
  };
}

export default ProductsList;
