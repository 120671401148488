import React from 'react';
import PropTypes from 'prop-types';

import Filter from './Filter';
import { fetchRetailers, appendRetailers } from '../../actions/retailers';

export default function Retailers(props) {
  return (
    <Filter
      value={props.value}
      title="RETAILERS"
      searchKey="q"
      fetcher={fetchRetailers}
      appender={appendRetailers}
      stateName="retailers"
      attr="retailer_ids"
      searchable={false}
    />
  );
}

Retailers.propTypes = {
  value: PropTypes.array
};
