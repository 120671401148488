import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Autosuggest from 'react-autosuggest';

import history from '../../lib/history';
import { getQuery, toQS } from '../../lib/query-params';
import { search } from '../../actions/search';
import Loader from '../Loader';
import BrandLabel from '../BrandLabel';

/**
 * @todo Better to re-write this component without using Autosuggest?
 */

// When suggestion is clicked, Autosuggest needs to populate the input element
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = (suggestion) => suggestion.name;

// Use your imagination to render suggestions.
const renderSuggestion = (suggestion) => (
  <div>
    <div>{suggestion.name}</div>
    <BrandLabel brand_id={suggestion.brand_id}>
      {suggestion.brand_name}
    </BrandLabel>
  </div>
);

class SearchBox extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onSuggestionsFetchRequested =
      this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested =
      this.onSuggestionsClearRequested.bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    this.shouldRenderSuggestions = this.shouldRenderSuggestions.bind(this);

    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.
    this.state = {
      redirected: false,
      value: props.q,
      suggestions: []
    };
  }

  onChange(event, { newValue, method }) {
    this.setState({ value: newValue });
  }

  onSuggestionsFetchRequested({ value }) {
    const params = {
      q: value,
      per_page: 10,
      detail: 'biz'
    };
    this.props.dispatch(search(params));
  }

  onSuggestionSelected(
    e,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) {
    if (this.props.onSelect) {
      this.props.onSelect(suggestion);
    } else {
      // @todo: use history api here once we move to function components
      // more info https://github.com/q-m/qmi-dashboard/issues/154
      this.setState(
        { redirected: true },
        () => (window.location.href = `/products/${suggestion.id}`)
      );
    }
    this.setState({ value: '' });
  }

  shouldRenderSuggestions(value) {
    return value.length > 2; // at least 2 characters
  }

  onSuggestionsClearRequested() {
    this.setState({ suggestions: [], redirected: false });
  }

  render() {
    const { value } = this.state;
    const { items, loading, onSelect, placeholder } = this.props;

    // Autosuggest will pass through all these props to the input element.
    const inputProps = {
      placeholder,
      onChange: this.onChange,
      type: 'search',
      value,
      onKeyDown: (e) => {
        if (onSelect) return;
        if (parseInt(e.keyCode, 10) === 13 && !this.state.redirected) {
          const query = window.location.pathname.includes('products')
            ? getQuery()
            : {};
          const params = toQS({ ...query, page: 1, q: this.state.value });
          return history.push(`/products?${params}`);
        }
      }
    };

    return (
      <div className="searchbox">
        <Autosuggest
          suggestions={items}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          shouldRenderSuggestions={this.shouldRenderSuggestions}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
        />
        <Loader loading={loading} text=" " />
      </div>
    );
  }
}

SearchBox.propTypes = {
  dispatch: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  q: PropTypes.string,
  onSelect: PropTypes.func,
  placeholder: PropTypes.string
};

SearchBox.defaultProps = {
  q: ''
};

function select(state, props) {
  return {
    ...state.search,
    q: history.location.query.q || ''
  };
}

export default connect(select)(SearchBox);
