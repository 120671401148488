import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Dots } from '../Loader';

class ButtonWithLoader extends Component {
  render() {
    const { children, loading, bsStyle, bsSize, disabled, ...props } =
      this.props;
    const loaderProps = {};
    if (bsStyle === 'primary') loaderProps.color = '#fff';
    if (bsSize !== 'large') loaderProps.size = 8;
    return (
      <Button
        {...props}
        bsStyle={bsStyle}
        bsSize={bsSize}
        disabled={disabled || loading}>
        {children}{' '}
        {loading && <Dots {...loaderProps} style={{ display: 'inline' }} />}
      </Button>
    );
  }
}

ButtonWithLoader.propTypes = {
  loading: PropTypes.bool,
  bsStyle: PropTypes.string,
  bsSize: PropTypes.string,
  disabled: PropTypes.bool
};

export default ButtonWithLoader;
