import ReactDOM from 'react-dom';
import React from 'react';
import App from './containers/App';

import { Router, Route } from 'react-router';
import { Provider } from 'react-redux';
import Cookies from 'cookies-js';

import history from './lib/history';
import Bugsnag from './lib/bugsnag';
import store from './store';
import rootSaga from './sagas';

import { fetchCurrentUser } from './actions/user';
import './styles/index.scss';

store.runSaga(rootSaga);

// Dispatch this first because the user needs to be authenticated first
// Redirection happens based on this
const token = Cookies.get('authentication_token');
if (token) store.dispatch(fetchCurrentUser(token));

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

function render(Component) {
  ReactDOM.render(
    <ErrorBoundary>
      <Provider store={store}>
        <Router history={history}>
          <Route component={Component} />
        </Router>
      </Provider>
    </ErrorBoundary>,
    document.getElementById('app')
  );
}

render(App);
