import React from 'react';
import PropTypes from 'prop-types';
import { t } from '../../i18n';

function Title({ product, score }) {
  return score === 'A' ? (
    <div>
      <h2>{t('HEALTH_IMPROVEMENT_TITLE')}</h2>
      <p>
        {t('HEALTH_IMPROVEMENT_IN_SCHIJF_BODY_1', { product: product.name })}{' '}
        <em>{t('HEALTH_IMPROVEMENT_IN_SCHIJF_BODY_2')}</em>
        {t('HEALTH_IMPROVEMENT_IN_SCHIJF_BODY_3')}
      </p>
    </div>
  ) : (
    <div>
      <h2>{t('HEALTH_IMPROVEMENT_TITLE')}</h2>
      <p>
        {t('HEALTH_IMPROVEMENT_UIT_SCHIJF_BODY_1', { product: product.name })}{' '}
        <em>{t('HEALTH_IMPROVEMENT_UIT_SCHIJF_BODY_2')}</em>
        {t('HEALTH_IMPROVEMENT_UIT_SCHIJF_BODY_3')}{' '}
        <em>{t(`HEALTH_${score}`)}</em>{' '}
        {t('HEALTH_IMPROVEMENT_UIT_SCHIJF_BODY_4')}
      </p>
    </div>
  );
}

Title.propTypes = {
  product: PropTypes.object.isRequired,
  score: PropTypes.string.isRequired
};

export default Title;
