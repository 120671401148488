// https://docs.bugsnag.com/platforms/javascript/react/
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { BUGSNAG_API_KEY, APP_ENV } from '../config';
import app from '../../package.json';

Bugsnag.start({
  apiKey: BUGSNAG_API_KEY || 'not set',
  appVersion: app.version,
  enabledReleaseStages: BUGSNAG_API_KEY ? ['production', 'staging'] : [],
  releaseStage: APP_ENV,
  autoTrackSessions: false, // avoid unnecessary traffic
  plugins: [new BugsnagPluginReact()]
});

export default Bugsnag;
