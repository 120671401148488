import React from 'react';
import PropTypes from 'prop-types';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { t } from '../../i18n';

function Existing(props) {
  const { comparisons, onClick } = props;
  if (!comparisons.length) return null;
  return (
    <DropdownButton
      id="existing-comparisons"
      title={t('ADD_TO_COMPARISON_LIST')}
      pullRight>
      {comparisons.map((c, index) => (
        <MenuItem eventKey={index} key={index} onClick={(e) => onClick(c)}>
          {c.name} ({c.products.length})
        </MenuItem>
      ))}
    </DropdownButton>
  );
}

Existing.propTypes = {
  comparisons: PropTypes.array,
  onClick: PropTypes.func.isRequired
};

export default Existing;
