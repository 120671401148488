import {
  LOGIN_SUCCESS,
  LOGIN_REQUEST,
  LOGIN_FAILURE,
  ACCEPT_INVITE_SUCCESS,
  ACCEPT_INVITE_REQUEST,
  ACCEPT_INVITE_FAILURE,
  LOGOUT_SUCCESS,
  LOGOUT_REQUEST,
  LOGOUT_FAILURE,
  FETCH_CURRENT_USER_REQUEST,
  FETCH_CURRENT_USER_SUCCESS,
  FETCH_CURRENT_USER_FAILURE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  FETCH_INVITE_REQUEST,
  FETCH_INVITE_SUCCESS,
  FETCH_INVITE_FAILURE
} from '../actions/user';

const initialState = {
  name: '',
  company_name: '',
  position: '',
  department: '',
  email: '',
  address: '',
  zipcode: '',
  city: '',
  phone: '',
  locale: 'nl',
  authentication_token: '',
  owned_brands: [],
  plan: {},
  loading: false,
  loggedIn: false
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case ACCEPT_INVITE_REQUEST:
    case UPDATE_PROFILE_REQUEST:
    case FETCH_CURRENT_USER_REQUEST:
    case LOGIN_REQUEST:
    case RESET_PASSWORD_REQUEST:
    case FETCH_INVITE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case FETCH_CURRENT_USER_SUCCESS:
    case UPDATE_PROFILE_SUCCESS:
    case ACCEPT_INVITE_SUCCESS:
    case LOGIN_SUCCESS:
      return {
        ...state,
        ...action.payload.user,
        loading: false,
        loggedIn: true
      };
    case FETCH_INVITE_SUCCESS:
      return {
        ...state,
        ...action.payload.user,
        loading: false,
        loggedIn: false
      };
    case FETCH_CURRENT_USER_FAILURE:
    case LOGIN_FAILURE:
    case ACCEPT_INVITE_FAILURE:
      return {
        ...initialState,
        loading: false
      };
    case UPDATE_PROFILE_FAILURE:
    case LOGOUT_FAILURE:
    case RESET_PASSWORD_SUCCESS:
    case RESET_PASSWORD_FAILURE:
    case FETCH_INVITE_FAILURE:
      return {
        ...state,
        loading: false
      };
    case LOGOUT_REQUEST:
    case LOGOUT_SUCCESS:
      return { ...initialState };
    default:
      return state;
  }
}
