export const FETCH_CERTIFICATES = 'FETCH_CERTIFICATES';
export const FETCH_CERTIFICATES_REQUEST = `${FETCH_CERTIFICATES}_REQUEST`;
export const FETCH_CERTIFICATES_SUCCESS = `${FETCH_CERTIFICATES}_SUCCESS`;
export const FETCH_CERTIFICATES_FAILURE = `${FETCH_CERTIFICATES}_FAILURE`;

export const APPEND_CERTIFICATES = 'APPEND_CERTIFICATES';
export const APPEND_CERTIFICATES_REQUEST = `${APPEND_CERTIFICATES}_REQUEST`;
export const APPEND_CERTIFICATES_SUCCESS = `${APPEND_CERTIFICATES}_SUCCESS`;
export const APPEND_CERTIFICATES_FAILURE = `${APPEND_CERTIFICATES}_FAILURE`;

export function fetchCertificates(params) {
  return { type: FETCH_CERTIFICATES, ...params };
}

export function appendCertificates(params) {
  return {
    type: APPEND_CERTIFICATES,
    certificate_ids: params.ids,
    per_page: 500
  };
}
