import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Toolbar from '../../components/Toolbar';

import { t } from '../../i18n';

class Brands extends Component {
  render() {
    const { brands } = this.props;
    return (
      <Grid>
        <Toolbar>
          <h2>
            {t('MY_BRANDS')} <small>({brands.length})</small>
          </h2>
        </Toolbar>
        <ul className="list list-unstyled list-qmi">
          {brands.map((item, index) => (
            <li key={index}>
              <Link to={`/products?brand_ids[]=${item.id}`}>{item.name}</Link>
            </li>
          ))}
        </ul>
      </Grid>
    );
  }
}

Brands.propTypes = {
  dispatch: PropTypes.func.isRequired,
  brands: PropTypes.array.isRequired
};

function select(state) {
  return {
    brands: state.user.owned_brands
  };
}

export default connect(select)(Brands);
