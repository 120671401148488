import React from 'react';
import PropTypes from 'prop-types';

import { t } from '../../i18n';
import DetailSwitch from './detail-switch';
import Title from './Title';
import ScoreTableA from './ScoreTableA';
import ScoreTableOther from './ScoreTableOther';
import { hasSingleStaticRule, subscoreRules } from './utils';
import styles from './styles';
import Bugsnag from '../../lib/bugsnag';

class HealthImprovement extends React.Component {
  constructor(props) {
    super(props);
    this.state = { detail: props.initialDetail };
  }

  render() {
    const { detail } = this.state;
    const { product, scoring_report } = this.props;
    const score = product.personal_health_score;
    const button = (
      <DetailSwitch
        active={detail}
        onClick={(b) => this.setState({ detail: b })}
        style={styles.button}
      />
    );
    const health_report = scoring_report.personal_health_score;

    if (hasSingleStaticRule(health_report)) {
      return (
        <div>
          <Title product={product} score={score} />
          <p>{t('HEALTH_IMPROVEMENT_STATIC')}</p>
        </div>
      );
    } else if (score && !health_report) {
      // Shouldn't really happen! Report warning when possible
      try {
        Bugsnag.notify(
          'NoHealthReportError',
          `No health_report for scoring_report of product ${product.id}`
        );
      } catch (e) {
        /* ok if fails */
      }
      return (
        <div>
          <Title product={product} score={score} />
          <p>
            <i>{t('HEALTH_IMPROVEMENT_PENDING')}</i>
          </p>
        </div>
      );
    } else if (score === 'A') {
      return (
        <div>
          <Title product={product} score={score} />
          <p>{t('HEALTH_IMPROVEMENT_IN_SCHIJF_HEADING')}</p>
          {button}
          <ScoreTableA {...{ health_report, detail }} />
        </div>
      );
    } else if (score && (health_report.subscores || []).length > 0) {
      return <div>{t('HEALTH_IMPROVEMENT_MISSING')}</div>;
    } else if (score) {
      const aScoreObtainable = subscoreRules(health_report, 'A').length > 0;
      return (
        <div>
          <Title product={product} score={score} />
          {button}
          <ScoreTableOther {...{ health_report, detail }} />

          {aScoreObtainable ? (
            <div style={styles.sectionPara}>
              <p>{t('HEALTH_IMPROVEMENT_UIT_SCHIJF_HEADING')}</p>
              {button}
              <ScoreTableA {...{ health_report, detail }} />
            </div>
          ) : (
            <p style={styles.sectionPara}>
              {t('HEALTH_IMPROVEMENT_NOOIT_SCHIJF_HEADING')}
            </p>
          )}
        </div>
      );
    } else {
      return <div>{t('HEALTH_IMPROVEMENT_ABSENT')}</div>;
    }
  }
}

HealthImprovement.propTypes = {
  product: PropTypes.object.isRequired,
  scoring_report: PropTypes.object.isRequired,
  initialDetail: PropTypes.bool
};

export default HealthImprovement;

// If you'd like to try out this component, this would be suitable test data.
//
// const natrium_value = score === 'A' ? 5 : score === 'B1' ? 80 : score === 'B2' ? 120 : score === 'C1' ? 260 : 395;
// const scoring_report = {
//   personal_health_score: {
//     id: 'personal_health_score',
//     type: 'HealthScore',
//     value: score,
//     label: healthLabel(score),
//     children: [
//       {
//         id: 'A',
//         type: 'HealthScore',
//         value: score === 'A' ? 'A' : null,
//         label: healthLabel('A'),
//         children: [
//           {
//             id: 'energy',
//             label: 'Energie',
//             type: 'HealthRuleScore',
//             value: score === 'A' ? 'A' : null,
//             rule: {
//               nutrient_code: 'energy',
//               cmp: '≤',
//               value: 2,
//               unit: 'kcal',
//               per: '100g',
//               score: 'A',
//               type: 'nutrient_rule',
//             },
//             rule_input: {
//               value: score === 'A' ? 1.3 : 3.2,
//               portion: '25g',
//               source: 'product',
//             },
//             actions: score === 'A' ? [] : [
//               'energie verminderen tot ≤ 2 kcal per 100g',
//             ],
//           },
//           {
//             id: 'protein_vs_energy',
//             label: 'Eiwitten vs. energie',
//             type: 'HealthRuleScore',
//             value: score === 'A' ? 'A' : null,
//             rule: {
//               nutrient_code: 'protein',
//               cmp: '≥',
//               value: 12,
//               unit: '%',
//               of: 'energy',
//               score: 'A',
//               type: 'nutrient_comparison_rule',
//             },
//             rule_input: {
//               value: score === 'A' ? 15 : 3,
//               portion: '25g',
//               source: 'product',
//             },
//             actions: [],
//           },
//           {
//             id: 'natrium',
//             label: 'Natrium',
//             type: 'HealthRuleScore',
//             value: score === 'A' ? 'A' : null,
//             rule: {
//               nutrient_code: 'natrium',
//               cmp: '≤',
//               value: 100,
//               unit: 'mg',
//               per: '100g',
//               score: 'A',
//               type: 'nutrient_rule',
//             },
//             rule_input: {
//               value: natrium_value * 4,
//               portion: '25g',
//               source: 'product',
//             },
//             actions: [],
//           },
//           {
//             id: 'added_salt',
//             label: 'Geen zout toegevoegd',
//             type: 'HealthRuleScore',
//             value: 'A',
//             rule: {
//               ingredient_base_type: 'salt',
//               present: false,
//               score: 'A',
//               type: 'ingredient_base_rule',
//             },
//             rule_input: {
//               value: true,
//               source: 'product',
//             },
//             actions: [],
//           },
//         ],
//       },
//       {
//         id: 'B1',
//         type: 'HealthScore',
//         value: ['A', 'B1'].includes(score) ? 'B1' : null,
//         label: healthLabel('B1'),
//         children: [
//           {
//             id: 'natrium',
//             label: 'Natrium',
//             type: 'HealthRuleScore',
//             value: ['A', 'B1'].includes(score) ? 'B1' : null,
//             rule: {
//               nutrient_code: 'natrium',
//               cmp: '≤',
//               value: 100,
//               unit: 'mg',
//               per: 'portion',
//               score: 'A',
//               type: 'nutrient_rule',
//             },
//             rule_input: {
//               value: natrium_value,
//               portion: '25g',
//               source: 'product',
//             },
//             actions: ['A', 'B1'].includes(score) ? [] : [
//               'natrium verminderen tot ≤ 100mg per portie',
//             ],
//           },
//         ],
//       },
//       {
//         id: 'B2',
//         type: 'HealthScore',
//         value: ['A', 'B1', 'B2'].includes(score) ? 'B2' : null,
//         label: healthLabel('B2'),
//         children: [
//           {
//             id: 'natrium',
//             label: 'Natrium',
//             type: 'HealthRuleScore',
//             value: ['A', 'B1', 'B2'].includes(score) ? 'B2' : null,
//             rule: {
//               nutrient_code: 'natrium',
//               cmp: '≤',
//               value: 200,
//               unit: 'mg',
//               per: 'portion',
//               score: 'A',
//               type: 'nutrient_rule',
//             },
//             rule_input: {
//               value: natrium_value,
//               portion: '25g',
//               source: 'product',
//             },
//             actions: ['A', 'B1', 'B2'].includes(score) ? [] : [
//               'natrium verminderen tot ≤ 200mg per portie',
//             ],
//           },
//         ],
//       },
//       {
//         id: 'C1',
//         type: 'HealthScore',
//         value: ['A', 'B1', 'B2', 'C1'].includes(score) ? 'C1' : null,
//         label: healthLabel('C1'),
//         children: [
//           {
//             id: 'natrium',
//             label: 'Natrium',
//             type: 'HealthRuleScore',
//             value: ['A', 'B1', 'B2', 'C1'].includes(score) ? 'C1' : null,
//             rule: {
//               nutrient_code: 'natrium',
//               cmp: '≤',
//               value: 300,
//               unit: 'mg',
//               per: 'portion',
//               score: 'A',
//               type: 'nutrient_rule',
//             },
//             rule_input: {
//               value: natrium_value,
//               portion: '25g',
//               source: 'product',
//             },
//             actions: ['A', 'B1', 'B2', 'C1'].includes(score) ? [] : [
//               'natrium verminderen tot ≤ 300mg per portie',
//             ],
//           },
//         ],
//       },
//     ],
//   },
// };
