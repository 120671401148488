import React from 'react';
import PropTypes from 'prop-types';
import { t } from '../../i18n';

import ScoreTable from './ScoreTable';
import ScorePer from './ScorePer';
import {
  scoreValueMark,
  subscoreRules,
  nutrientNameEx,
  scoreTooltip,
  nutrientInfoTooltip
} from './utils';

// Table with details of a score within the Wheel of Five
function ScoreTableA({ health_report, detail, ...props }) {
  const rules = subscoreRules(health_report, 'A');

  // If there is a single or-rule for A, each branch gets its own table; else one table.
  const hasSingleOr =
    rules.length === 1 && (rules[0].rule || {}).type === 'or_rule';

  if (hasSingleOr) {
    // A scores have a single or-rule: show the two branches as separate tables.
    const branches = rules[0].children;
    return branches.map((branch, idx) => {
      const branch_rules = branch.children;
      return (
        <div key={branch.id}>
          <ScoreTable
            scoreLabels={healthLabelsA}
            selectedScore={health_report.value}
            scores={
              detail ? detailScores(branch_rules) : basicScores(branch_rules)
            }
            {...props}
          />
          <ScorePer rules={branch_rules} style={{ width: 320 }} />
          {idx < rules[0].children.length - 1 && (
            <p>{t('HEALTH_IMPROVEMENT_IN_SCHIJF_OR')}</p>
          )}
        </div>
      );
    });
  } else {
    return (
      <div>
        <ScoreTable
          scoreLabels={healthLabelsA}
          selectedScore={health_report.value}
          scores={detail ? detailScores(rules) : basicScores(rules)}
          {...props}
        />
        <ScorePer rules={rules} style={{ width: 320 }} />
      </div>
    );
  }
}

ScoreTableA.propTypes = {
  health_report: PropTypes.object.isRequired,
  detail: PropTypes.bool
};

const healthLabelsA = {
  A: t('HEALTH_A')
};

function basicScores(rules) {
  return rules.flatMap(({ id, value, rule, rule_input }) => {
    const resultValue =
      rule_input || !rule || rule.type === 'or_rule' ? !!value : null;
    return {
      id,
      label: nutrientNameEx(id),
      labelTooltip: nutrientInfoTooltip(id),
      score: value,
      // if rule_input is null, there was missing input data,
      //   except when rule is also missing, then there was an old scoring_object
      value: scoreValueMark(resultValue),
      // omit the tooltip in case of old scoring_objects
      tooltip: rule ? scoreTooltip(rule, rule_input) : null
    };
  });
}

function detailScores(rules) {
  return rules.flatMap(({ id, value, rule, rule_input, children, orPrev }) => {
    if (rule.type === 'or_rule') {
      if (children.length === 0) {
        // we don't want to break the UI with old-style or-rules (no expansion)
        return {
          id,
          label: nutrientNameEx(id),
          labelTooltip: nutrientInfoTooltip(id),
          score: value,
          value: scoreValueMark(!!value),
          orPrev
        };
      } else {
        // here we assume there are several branches with only one child
        children = children.flatMap((branch) => branch.children);
        children = children.map((rule, i) => ({ ...rule, orPrev: i > 0 }));
        console.log(children);
        return detailScores(children);
      }
    } else if (
      rule.type === 'nutrient_rule' ||
      rule.type === 'nutrient_comparison_rule'
    ) {
      const inputValue = (rule_input || {}).value;
      // don't let the circle overlap the arrow - @todo move overlap-avoidence to {ScoreScaleLinear}
      //   `|| 0` to make sure `NaN` still results in a maximum value
      const maxValue = Math.max(rule.value * 2, inputValue * 1.15 || 0);
      const boundaries = [
        { label: '0', value: 0 },
        { label: `${rule.value}${rule.unit}`, value: rule.value },
        { label: '', value: maxValue, marker: 'arrow' }
      ];
      const common = {
        id,
        label: nutrientNameEx(id),
        labelTooltip: nutrientInfoTooltip(id),
        score: value,
        value: inputValue,
        tooltip: scoreTooltip(rule, rule_input),
        orPrev
      };

      if (rule.cmp === '<' || rule.cmp === '≤') {
        return { ...common, boundaries };
      } else {
        return { ...common, boundaries: boundaries.reverse() };
      }
    }
    //if (rule.type === 'ingredient_base_rule' || rule.type === 'static_rule')
    else {
      return {
        id,
        label: nutrientNameEx(id),
        labelTooltip: nutrientInfoTooltip(id),
        score: value,
        value: scoreValueMark(rule_input ? !!value : null),
        tooltip: scoreTooltip(rule, rule_input),
        orPrev
      };
    }
  });
}

export default ScoreTableA;
