// Read more: https://redux-saga.github.io/redux-saga/

import qs from 'qs';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import i18n, { t, supported } from '../i18n';
import moment from 'moment';
import Cookies from 'cookies-js';
import { show as Snackbar } from 'js-snackbar';
import LocalStore from 'store';

import store from '../store';
import browserHistory from '../lib/history';
import * as api from '../lib/api';
import { toQS } from '../lib/query-params';
import * as comparisons from '../lib/comparisons';
import { getOwnedBrandIds, apiErrorMessage } from '../lib/helpers';
import { isDev, API_ROOT } from '../config';
import { PER_PAGE, PER_PAGE_FILTER } from '../constants';

import { NOTIFY, CHANGE_LANGUAGE } from '../actions/ui';
import {
  LOGIN,
  LOGOUT,
  RESET_PASSWORD,
  FETCH_CURRENT_USER,
  UPDATE_PROFILE,
  ACCEPT_INVITE,
  FETCH_INVITE
} from '../actions/user';
import {
  FETCH_PRODUCTS,
  FETCH_PRODUCT,
  FETCH_SCORING_REPORT,
  EXPORT_CSV
} from '../actions/products';

// filters (currently)
import { FETCH_BRANDS, APPEND_BRANDS } from '../actions/brands';
import { FETCH_USAGES, APPEND_USAGES } from '../actions/usages';
import {
  FETCH_CERTIFICATES,
  APPEND_CERTIFICATES
} from '../actions/certificates';
import { FETCH_RETAILERS, APPEND_RETAILERS } from '../actions/retailers';

import { FETCH_NUTRIENTS } from '../actions/nutrients';
import { SEARCH } from '../actions/search';
import {
  FETCH_COMPARISONS,
  CREATE_COMPARISON,
  UPDATE_COMPARISON,
  REMOVE_COMPARISON
} from '../actions/comparisons';

const API12 = '/api/v1.2';

function getErrors(e) {
  const errors =
    e.errors &&
    e.errors
      .map((e) => e.detail)
      .filter((e) => e)
      .join(', ');
  return errors || e.detail || 'Something went wrong';
}

/**
 * Generic wrapper for API calls
 * - Dispatches *_REQUEST action
 * - Calls api with the endpoint, query params and request body
 * - Attaches query params to the response and dispatches *_SUCCESS action
 * - In case of failure, dispatches *_FAILURE with the error
 */

function* request(ACTION, apiFn, endpoint, params = {}, body = {}) {
  try {
    yield put({ type: `${ACTION}_REQUEST`, ...params });
    const data = yield call(
      apiFn,
      `${API12}${endpoint}?${parameterize(params)}`,
      body
    );
    // if there is no response data, we are passing back the initial payload
    const _payload = data || body;
    _payload.params = params;
    yield put({ type: `${ACTION}_SUCCESS`, payload: _payload });
    return data;
  } catch (e) {
    const error = getErrors(e);
    yield put({ type: `${ACTION}_FAILURE`, error });
    return { error };
  }
}

function* login({ email, password }) {
  yield put({ type: `${LOGIN}_REQUEST` });

  try {
    const body = { user: { email, password }, plan: 'full' };
    const res = yield call(api.create, API12 + '/user/sign_in', body);
    if (res.success) {
      yield put({ type: `${LOGIN}_SUCCESS`, payload: res });
      const secure = !isDev; // we want the cookie to be stored in dev over non-https
      Cookies.set('authentication_token', res.user.authentication_token, {
        secure
      });
      const search = window.location.search.slice(1); // query string
      const { next } = qs.parse(search); // parse it
      browserHistory.push(next || '/'); // redirect
    } else {
      yield put({ type: `${LOGIN}_FAILURE` });
      yield put({ type: NOTIFY, text: apiErrorMessage(res) });
    }
  } catch (e) {
    yield put({ type: `${FETCH_CURRENT_USER}_FAILURE` });
    yield put({ type: NOTIFY, text: apiErrorMessage(e) });
  }
}

function* logout() {
  Cookies.expire('authentication_token');
  browserHistory.push('/login');
  yield* request(LOGOUT, api.get, '/user/sign_out');
}

function* fetchCurrentUser({ authentication_token }) {
  yield put({ type: `${FETCH_CURRENT_USER}_REQUEST` });

  try {
    const res = yield call(
      api.get,
      `${API12}/user/status?${parameterize({ authentication_token })}&plan=full`
    );
    // We don't really need to check this anymore, since the API returns status 403, which throws an exception.
    // In the past, plan=full wasn't supported, and we used to look at the logged_in property.
    // This check can be removed in 2023 or so.
    if (res.logged_in) {
      yield put({ type: `${FETCH_CURRENT_USER}_SUCCESS`, payload: res });
    } else {
      yield put({ type: `${FETCH_CURRENT_USER}_FAILURE` });
      yield put({ type: NOTIFY, text: apiErrorMessage(res) });
    }
  } catch (e) {
    yield put({ type: `${FETCH_CURRENT_USER}_FAILURE` });
    yield put({ type: NOTIFY, text: apiErrorMessage(e) });
  }
}

function* updateProfile({ type, ...params }) {
  const body = { user: { ...params } };
  const res = yield* request(UPDATE_PROFILE, api.update, '/user', {}, body);
  if (!res.error) yield put({ type: NOTIFY, text: t('MSG_PROFILE_UPDATED') });
}

function* acceptInvite({ user }) {
  const body = { user };
  yield put({ type: `${ACCEPT_INVITE}_REQUEST` });
  try {
    const res = yield call(api.update, `${API12}/user/invitation/accept`, body);
    if (res.success) {
      yield put({ type: `${ACCEPT_INVITE}_SUCCESS`, payload: res });
      yield put({ type: NOTIFY, text: t('SIGN_UP_SUCCESS') });
      const secure = !isDev; // we want the cookie to be stored in dev over non-https
      Cookies.set('authentication_token', res.user.authentication_token, {
        secure
      });
      browserHistory.push('/');
    } else {
      yield put({ type: `${ACCEPT_INVITE}_FAILURE` });
      yield put({ type: NOTIFY, text: res.errors.join('\n') });
    }
  } catch (e) {
    yield put({ type: `${ACCEPT_INVITE}_FAILURE` });
    yield put({ type: NOTIFY, text: t('SIGN_UP_FAILURE') });
  }
}

function* fetchInvite({ invitation_token }) {
  const q = { invitation_token };
  yield* request(FETCH_INVITE, api.get, '/user/invitation/accept', q);
}

function* resetPassword({ email }) {
  const res = yield* request(RESET_PASSWORD, api.get, '/user/password/reset', {
    email
  });
  if (!res.error)
    yield put({ type: NOTIFY, text: t('MSG_RESET_PASSWORD_SENT') });
}

function* fetchProducts({ type, ...params }) {
  // automatically include owned brand_ids in the query param when 'owned' param is present in it
  if ('owned' in params) {
    params.brand_ids = getOwnedBrandIds();
    delete params.owned;
  }
  if ('columns' in params) {
    delete params.columns;
  }
  const q = {
    health_group_present: 1,
    detail: 'biz',
    per_page: PER_PAGE,
    ...params
  };
  yield* request(type, api.get, '/products', q);
}

function* fetchScoringReport({ type, id }) {
  const q = { health_group_present: 1, detail: 'biz' };
  yield* request(type, api.get, `/products/${id}/scoring_report`, q);
}

function exportCsv({ type, ...params }) {
  if ('owned' in params) {
    params.brand_ids = getOwnedBrandIds();
    delete params.owned;
  }
  if ('columns' in params) {
    delete params.columns;
  }
  const q = {
    health_group_present: 1,
    detail: 'biz',
    per_page: PER_PAGE,
    ...params
  };
  downloadCSV(q);
}

/**
 * FILTERS BEGIN
 */

function* fetchBrands({ type, ...params }) {
  const { brand_ids, ...rest } = params; // remove self
  const q = { health_group_present: 1, per_page: PER_PAGE_FILTER, ...rest };
  yield* request(FETCH_BRANDS, api.get, '/products/brands', q);
}

function* fetchCertificates({ type, ...params }) {
  const { certificate_ids, ...rest } = params; // remove self
  const q = { health_group_present: 1, per_page: PER_PAGE_FILTER, ...rest };
  if ('owned' in rest) q.brand_ids = getOwnedBrandIds(); // when looking at owned products, only show relevant data
  yield* request(FETCH_CERTIFICATES, api.get, '/products/certificates', q);
}

function* fetchUsages({ type, ...params }) {
  const { usage_ids, ...rest } = params; // remove self
  const q = {
    health_group_present: 1,
    flat: 1,
    per_page: PER_PAGE_FILTER,
    ...rest
  };
  if ('owned' in rest) q.brand_ids = getOwnedBrandIds(); // when looking at owned products, only show relevant data
  yield* request(FETCH_USAGES, api.get, '/products/usages', q);
}

function* fetchRetailers({ type, ...params }) {
  const { retailer_ids, ...rest } = params; // remove self
  const q = { priority: 2, accessible: 1, ...rest };
  if ('owned' in rest) q.brand_ids = getOwnedBrandIds(); // when looking at owned products, only show relevant data
  yield* request(FETCH_RETAILERS, api.get, '/retailers', q);
}

function* appendBrands({ type, ...q }) {
  yield* request(APPEND_BRANDS, api.get, '/brands', q);
}

function* appendCertificates({ type, ...q }) {
  yield* request(APPEND_CERTIFICATES, api.get, '/products/certificates', q);
}

function* appendUsages({ type, ...q }) {
  yield* request(APPEND_USAGES, api.get, '/products/usages', q);
}

function* appendRetailers({ type, ...q }) {
  yield* request(APPEND_RETAILERS, api.get, '/retailers', q);
}

/**
 * FILTERS END
 */

function* fetchNutrients({ type, ...params }) {
  const q = { coded: 1, per_page: PER_PAGE, ...params };
  yield* request(FETCH_NUTRIENTS, api.get, '/nutrients', q);
}

function* search({ type, ...params }) {
  const q = { health_group_present: 1, per_page: PER_PAGE, ...params };
  yield* request(SEARCH, api.get, '/products', q);
}

/**
 * COMPARISONS BEGIN
 */

function* fetchComparisons({ type, ...params }) {
  try {
    yield put({ type: `${FETCH_COMPARISONS}_REQUEST` });
    yield put({
      type: `${FETCH_COMPARISONS}_SUCCESS`,
      payload: comparisons.list()
    });
  } catch (e) {
    console.log(e);
    yield put({ type: `${FETCH_COMPARISONS}_FAILURE`, error: e.toString() });
  }
}

function* createComparison({ type, name, products }) {
  try {
    yield put({ type: `${CREATE_COMPARISON}_REQUEST` });
    yield put({
      type: `${CREATE_COMPARISON}_SUCCESS`,
      payload: comparisons.add(name, products)
    });
    yield put({ type: NOTIFY, text: t('MSG_COMPARISON_CREATED') });
  } catch (e) {
    console.log(e);
    yield put({ type: `${CREATE_COMPARISON}_FAILURE`, error: e.toString() });
  }
}

function* updateComparison({ type, name, products, id, remove }) {
  // Todo: This has to be simplified a little,
  // we should not use `remove` param here, instead create a different function
  // or something
  try {
    yield put({ type: `${UPDATE_COMPARISON}_REQUEST` });
    yield put({
      type: `${UPDATE_COMPARISON}_SUCCESS`,
      payload: remove
        ? comparisons.removeProducts(products, id)
        : comparisons.updateProducts(name, products, id)
    });
    if (!remove) yield put({ type: NOTIFY, text: t('MSG_COMPARISON_UPDATED') });
  } catch (e) {
    console.log(e);
    yield put({ type: `${UPDATE_COMPARISON}_FAILURE`, error: e.toString() });
  }
}

function* removeComparison({ type, products, id }) {
  try {
    yield put({ type: `${REMOVE_COMPARISON}_REQUEST` });
    yield put({
      type: `${REMOVE_COMPARISON}_SUCCESS`,
      payload: comparisons.remove(id)
    });
  } catch (e) {
    console.log(e);
    yield put({ type: `${REMOVE_COMPARISON}_FAILURE`, error: e.toString() });
  }
}

/**
 * COMPARISONS END
 */

function notify({ type, ...params }) {
  Snackbar(params);
}

function changeLanguage({ type, lang }) {
  i18n.locale = supported.includes(lang) ? lang : 'nl';
  moment.locale(lang);
  LocalStore.set('lang', lang);
}

function* notifyError({ type, error }) {
  // we are only interested in '*_FAILURE' s
  if (!type.includes('_FAILURE')) return;
  // Ignore for the following types
  const ignore = [
    `${CREATE_COMPARISON}_FAILURE`,
    `${UPDATE_COMPARISON}_FAILURE`,
    `${REMOVE_COMPARISON}_FAILURE`,
    `${FETCH_SCORING_REPORT}_FAILURE`
  ];
  if (ignore.includes(type)) return;
  if (error) yield put({ type: NOTIFY, text: error });
}

// Use google analytics event tracking
// https://developers.google.com/analytics/devguides/collection/analyticsjs/events
function track({ type, ...rest }) {
  if (!window.ga) return;
  // track all except _SUCCESS and _REQUEST actions
  if (type.includes('_SUCCESS') || type.includes('_REQUEST')) return;
  const category = categorize(type);
  window.ga('send', 'event', category, type, '', rest);
}

// use them in parallel
export default function* rootSaga() {
  // User related
  yield takeEvery(LOGIN, login);
  yield takeEvery(LOGOUT, logout);
  yield takeEvery(FETCH_CURRENT_USER, fetchCurrentUser);
  yield takeEvery(UPDATE_PROFILE, updateProfile);
  yield takeEvery(RESET_PASSWORD, resetPassword);
  yield takeEvery(FETCH_INVITE, fetchInvite);
  yield takeEvery(ACCEPT_INVITE, acceptInvite);

  // Generic
  yield takeEvery(EXPORT_CSV, exportCsv);
  yield takeLatest(FETCH_PRODUCTS, fetchProducts);
  yield takeLatest(FETCH_PRODUCT, fetchProducts);
  yield takeLatest(FETCH_SCORING_REPORT, fetchScoringReport);
  yield takeLatest(FETCH_NUTRIENTS, fetchNutrients);
  yield takeLatest(SEARCH, search);

  // Filters
  yield takeLatest(FETCH_BRANDS, fetchBrands);
  yield takeLatest(FETCH_USAGES, fetchUsages);
  yield takeLatest(FETCH_CERTIFICATES, fetchCertificates);
  yield takeLatest(FETCH_RETAILERS, fetchRetailers);
  yield takeLatest(APPEND_BRANDS, appendBrands);
  yield takeLatest(APPEND_USAGES, appendUsages);
  yield takeLatest(APPEND_CERTIFICATES, appendCertificates);
  yield takeLatest(APPEND_RETAILERS, appendRetailers);

  // Comparisons
  yield takeLatest(FETCH_COMPARISONS, fetchComparisons);
  yield takeLatest(CREATE_COMPARISON, createComparison);
  yield takeLatest(UPDATE_COMPARISON, updateComparison);
  yield takeLatest(REMOVE_COMPARISON, removeComparison);

  // Notifications
  yield takeEvery(NOTIFY, notify);
  yield takeEvery(CHANGE_LANGUAGE, changeLanguage);

  yield takeEvery('*', notifyError);
  yield takeEvery('*', track);
}

function categorize(type) {
  const action = type.replace('_FAILURE', ''); // helps categorizing
  switch (action) {
    default:
      return `${action} category`;
  }
}

function parameterize(q) {
  // append auth token for all logged in users
  const {
    user: { authentication_token }
  } = store.getState();
  if (authentication_token) q.authentication_token = authentication_token;
  return toQS(q);
}

function downloadCSV(q) {
  const _form = document.querySelector('form.export-csv');
  const _input = document.querySelector('form.export-csv input');
  const form = _form || document.createElement('form');
  if (!_form) document.body.appendChild(form);
  form.className = 'export-csv';
  form.style.cssText = 'display: none';
  const { authentication_token, ...query } = qs.parse(parameterize(q));
  form.action = `${API_ROOT}/api/v1.2/products.csv?${toQS(query)}`;
  form.method = 'post';
  const input = _input || document.createElement('input');
  input.type = 'hidden';
  input.name = 'authentication_token';
  input.value = authentication_token;
  if (!_input) form.appendChild(input);
  form.submit();
}
