import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, FormGroup, FormControl, HelpBlock } from 'react-bootstrap';
import { connect } from 'react-redux';

import ButtonWithLoader from '../ButtonWithLoader';
import Beta from '../Beta';
import { t } from '../../i18n';

const initialState = {
  showModal: false,
  created: false,
  updated: false
};

class AddEdit extends Component {
  constructor(props) {
    super(props);
    this.createComparison = this.createComparison.bind(this);
    this.updateComparison = this.updateComparison.bind(this);
    this.state = {
      ...initialState,
      name: props.comparison.name
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { loading, created, updated } = props;
    // Close modal once created or updated
    // Don't set showModal until request completes
    const closeModal = !loading && (created || updated);
    return closeModal ? { showModal: !closeModal } : null;
  }

  render() {
    const { loading, error, children } = this.props;
    const { name } = this.state;
    const isCreate = !!this.props.onCreate;
    const onModify = isCreate ? this.createComparison : this.updateComparison;
    const TITLE = isCreate ? 'CREATE_NEW_COMPARISON' : 'EDIT_COMPARISON';
    return (
      <div>
        <a href="" onClick={(e) => this.open(e)}>
          {children}
        </a>
        <Modal show={this.state.showModal} onHide={(e) => this.close()}>
          <Modal.Header closeButton>
            <Modal.Title>
              {t(TITLE)} <Beta />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="text-center" onSubmit={onModify}>
              <FormGroup
                bsSize="large"
                validationState={(error && 'error') || null}>
                <FormControl
                  type="text"
                  value={name}
                  onChange={(e) => this.onChange(e)}
                  placeholder={t('COMPARISON_NAME_PLACEHOLDER')}
                />
                {error && <HelpBlock>{error}</HelpBlock>}
              </FormGroup>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <ButtonWithLoader
              disabled={!name}
              loading={loading}
              bsStyle="primary"
              onClick={onModify}>
              {t('SAVE')}
            </ButtonWithLoader>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  close() {
    this.setState({ ...initialState });
  }

  open(e) {
    e.preventDefault();
    this.setState({ ...initialState, showModal: true });
  }

  onChange(e) {
    this.setState({ name: e.target.value });
  }

  updateComparison(e) {
    e.preventDefault();
    const { onUpdate, selected, comparison } = this.props;
    if (!comparison.id) return;
    onUpdate(this.state.name, selected, comparison.id);
  }

  createComparison(e) {
    e.preventDefault();
    if (!this.state.name) return;
    const { onCreate, selected } = this.props;
    onCreate(this.state.name, selected);
  }
}

AddEdit.propTypes = {
  selected: PropTypes.array,
  error: PropTypes.string,
  loading: PropTypes.bool,
  created: PropTypes.bool,
  updated: PropTypes.bool,
  onUpdate: PropTypes.func,
  onCreate: PropTypes.func,
  comparison: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
};

AddEdit.defaultProps = {
  selected: [],
  comparison: {
    name: '',
    products: []
  }
};

function select(state, props) {
  return {
    ...state.comparisons
  };
}

export default connect(select)(AddEdit);
