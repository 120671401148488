export const LOGIN = 'LOGIN';
export const LOGIN_REQUEST = `${LOGIN}_REQUEST`;
export const LOGIN_SUCCESS = `${LOGIN}_SUCCESS`;
export const LOGIN_FAILURE = `${LOGIN}_FAILURE`;

export const LOGOUT = 'LOGOUT';
export const LOGOUT_REQUEST = `${LOGOUT}_REQUEST`;
export const LOGOUT_SUCCESS = `${LOGOUT}_SUCCESS`;
export const LOGOUT_FAILURE = `${LOGOUT}_FAILURE`;

export const FETCH_CURRENT_USER = 'FETCH_CURRENT_USER';
export const FETCH_CURRENT_USER_REQUEST = `${FETCH_CURRENT_USER}_REQUEST`;
export const FETCH_CURRENT_USER_SUCCESS = `${FETCH_CURRENT_USER}_SUCCESS`;
export const FETCH_CURRENT_USER_FAILURE = `${FETCH_CURRENT_USER}_FAILURE`;

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_REQUEST = `${RESET_PASSWORD}_REQUEST`;
export const RESET_PASSWORD_SUCCESS = `${RESET_PASSWORD}_SUCCESS`;
export const RESET_PASSWORD_FAILURE = `${RESET_PASSWORD}_FAILURE`;

export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_REQUEST = `${UPDATE_PROFILE}_REQUEST`;
export const UPDATE_PROFILE_SUCCESS = `${UPDATE_PROFILE}_SUCCESS`;
export const UPDATE_PROFILE_FAILURE = `${UPDATE_PROFILE}_FAILURE`;

export const ACCEPT_INVITE = 'ACCEPT_INVITE';
export const ACCEPT_INVITE_REQUEST = `${ACCEPT_INVITE}_REQUEST`;
export const ACCEPT_INVITE_SUCCESS = `${ACCEPT_INVITE}_SUCCESS`;
export const ACCEPT_INVITE_FAILURE = `${ACCEPT_INVITE}_FAILURE`;

export const FETCH_INVITE = 'FETCH_INVITE';
export const FETCH_INVITE_REQUEST = `${FETCH_INVITE}_REQUEST`;
export const FETCH_INVITE_SUCCESS = `${FETCH_INVITE}_SUCCESS`;
export const FETCH_INVITE_FAILURE = `${FETCH_INVITE}_FAILURE`;

export function login(email, password) {
  return { type: LOGIN, email, password };
}

export function logout() {
  return { type: LOGOUT };
}

export function fetchCurrentUser(authentication_token) {
  return { type: FETCH_CURRENT_USER, authentication_token };
}

export function resetPassword(email) {
  return { type: RESET_PASSWORD, email };
}

export function updateProfile(params) {
  return { type: UPDATE_PROFILE, ...params };
}

export function acceptInvite(user) {
  return { type: ACCEPT_INVITE, user };
}

export function fetchInvite(invitation_token) {
  return { type: FETCH_INVITE, invitation_token };
}
