import { combineReducers } from 'redux';

import ui from './ui';
import user from './user';
import brands from './brands';
import usages from './usages';
import search from './search';
import products from './products';
import nutrients from './nutrients';
import retailers from './retailers';
import comparisons from './comparisons';
import certificates from './certificates';

export default combineReducers({
  ui,
  user,
  brands,
  usages,
  search,
  products,
  nutrients,
  retailers,
  comparisons,
  certificates
});
