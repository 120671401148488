import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import browserHistory from '../lib/history';

import Header from '../components/Header';
import Footer from '../components/Footer';
import Routes from './Routes';

import { logout } from '../actions/user';

class App extends Component {
  componentDidMount() {
    this.check(this.props);
  }

  check({ loggedIn, loading, location }) {
    if (loading || loggedIn) return;
    const next = location.pathname;
    // if the user is on all other pages which doesn't need auth,
    // don't redirect
    // Todo: Find a better solution
    if (/\/login|\/reset-password|\/signup/.test(next)) return;

    browserHistory.push(`/login?next=${next}`);
  }

  render() {
    const { loggedIn, email } = this.props;
    return (
      <div className="wrapper">
        <Header
          loggedIn={loggedIn}
          email={email}
          onLogout={(e) => this.logout()}
        />
        <Routes {...this.props} />
        <Footer />
      </div>
    );
  }

  logout() {
    this.props.dispatch(logout());
  }
}

App.propTypes = {
  loggedIn: PropTypes.bool,
  loading: PropTypes.bool,
  email: PropTypes.string,
  location: PropTypes.object,
  dispatch: PropTypes.func.isRequired
};

App.defaultProps = {
  loggedIn: false,
  email: '',
  errors: []
};

function select(state, props) {
  return {
    lang: state.ui.lang,
    loggedIn: state.user.loggedIn,
    loading: state.user.loading,
    email: state.user.email,
    location: props.location
  };
}

export default connect(select)(App);
