import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, ButtonGroup, Button } from 'react-bootstrap';
import { t } from '../../i18n';

function PackageSize(props) {
  const { min, max, unit, onChange } = props;

  return (
    <div>
      {t('PACKAGE_SIZE')}
      <div className="flex-row middle-xs between-xs">
        <FormControl
          type="text"
          placeholder="min"
          value={min || ''}
          onChange={(e) => onChange('package_size_min', e.target.value)}
          className="flex-col-xs-3"
        />
        <FormControl
          type="text"
          placeholder="max"
          value={max || ''}
          onChange={(e) => onChange('package_size_max', e.target.value)}
          className="flex-col-xs-3"
        />
        <ButtonGroup className="flex-col-xs-5">
          <Button
            active={unit === 'g'}
            onClick={(e) =>
              onChange('package_size_unit', unit === 'g' ? '' : 'g')
            }>
            g
          </Button>
          <Button
            active={unit === 'ml'}
            onClick={(e) =>
              onChange('package_size_unit', unit === 'ml' ? '' : 'ml')
            }>
            ml
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
}

PackageSize.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  unit: PropTypes.string,
  onChange: PropTypes.func
};

export default PackageSize;
