import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { t } from '../../i18n';

function NoResults({ center, children }) {
  return (
    <div
      className={classnames('no-results text-muted', {
        'text-center': center
      })}>
      {children || t('NO_RESULTS')}
    </div>
  );
}

NoResults.propTypes = {
  center: PropTypes.bool,
  children: PropTypes.string
};

export default NoResults;
