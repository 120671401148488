import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, DropdownButton, MenuItem } from 'react-bootstrap';

import { t, supported } from '../../i18n';
import { changeLanguage } from '../../actions/ui';
import app from '../../../package.json';

class Footer extends Component {
  render() {
    const Year = new Date().getFullYear();
    const { lang } = this.props;
    const Text = (l) => t(`LANG_${l.toUpperCase()}`);
    const langs = ['en', 'nl'];
    return (
      <footer className="footer">
        <Grid>
          <div className="flex-row between-sm">
            <ul className="list list-inline">
              <li>
                v{app.version} &copy; {Year} QM intelligence B.V.
              </li>
              <li>
                <a href="mailto:support@qmintelligence.com">{t('SUPPORT')}</a>
              </li>
              <li>
                <a
                  href="http://www.qmintelligence.com/contact"
                  target="_blank"
                  rel="noreferrer">
                  {t('CONTACT')}
                </a>
              </li>
              <li>
                <a
                  href="http://www.qmintelligence.com/over"
                  target="_blank"
                  rel="noreferrer">
                  {t('ABOUT')}
                </a>
              </li>
            </ul>
            <ul className="list list-inline">
              <li>
                <DropdownButton
                  title={Text(lang)}
                  id="change-lang"
                  dropup
                  pullRight
                  bsStyle="link">
                  {langs.map((lang, index) => (
                    <MenuItem
                      key={index}
                      onSelect={(e) => this.changeLang(lang)}>
                      {Text(lang)}
                    </MenuItem>
                  ))}
                </DropdownButton>
              </li>
            </ul>
          </div>
        </Grid>
      </footer>
    );
  }

  changeLang(lang) {
    this.props.dispatch(changeLanguage(lang));
  }
}

Footer.propTypes = {
  lang: PropTypes.oneOf(supported),
  dispatch: PropTypes.func.isRequired
};

function select(state) {
  return {
    lang: state.ui.lang
  };
}

export default connect(select)(Footer);
