import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from './ScoreTooltip';

/*
 * SVG component for displaying a score marker (single cell)
 *
 * @see ScoreTable
 */
function ScoreMark({
  value,
  cellWidth,
  height,
  valueProps,
  tooltip,
  ...props
}) {
  return (
    <g {...props} className="score-mark">
      {typeof value === 'string' || typeof value === 'number' ? (
        <Tooltip message={tooltip}>
          <text
            x={cellWidth / 2}
            y={height / 2}
            dy=".6ex"
            {...valueProps}
            style={{ textAnchor: 'middle', ...valueProps.style }}>
            {value}
          </text>
        </Tooltip>
      ) : (
        <g transform={`translate(${cellWidth / 2}, ${height / 2})`}>{value}</g>
      )}
    </g>
  );
}

ScoreMark.defaultProps = {
  valueProps: {}
};

ScoreMark.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.node
  ]),
  cellWidth: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  tooltip: PropTypes.string,
  valueProps: PropTypes.object.isRequired
};

export default ScoreMark;
