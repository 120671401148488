import Filter from './Filter';

import Brands from './Brands';
import Certificates from './Certificates';
import Retailers from './Retailers';
import Usages from './Usages';
import Age from './Age';
import PackageSize from './PackageSize';
import Nutriscore from './Nutriscore';
import Health from './Health';

Filter.Brands = Brands;
Filter.Certificates = Certificates;
Filter.Retailers = Retailers;
Filter.Usages = Usages;
Filter.Age = Age;
Filter.PackageSize = PackageSize;
Filter.Nutriscore = Nutriscore;
Filter.Health = Health;

export default Filter;
