import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

let id = 0;

function tip({ message }) {
  return (
    <Tooltip id={`score-tooltip-${id++}`} style={{ fontSize: 12 }}>
      {message}
    </Tooltip>
  );
}

function ScoreTooltip({ message, placement, children, ...props }) {
  return message ? (
    <OverlayTrigger
      {...props}
      overlay={tip({ message, placement })}
      placement={placement}>
      {children}
    </OverlayTrigger>
  ) : (
    children
  );
}

ScoreTooltip.defaultProps = {
  placement: 'bottom'
};

ScoreTooltip.propTypes = {
  children: PropTypes.element.isRequired,
  message: PropTypes.string,
  placement: PropTypes.string.isRequired
};

export default ScoreTooltip;
