import React from 'react';
import PropTypes from 'prop-types';
import { t } from '../../i18n';

function perDescription(per, portion) {
  return per === 'portion' ? t('PER_PORTION_OF', { portion: portion }) : per;
}

function ScorePer({ rules, style, ...props }) {
  // gather unique per values with their portion (there should be just one portion)
  let perValues = {};
  (rules || []).forEach(({ rule, rule_input }) => {
    if (rule && rule.per) {
      perValues[rule.per] = (rule_input || {}).portion;
    }
  });

  if (Object.keys(perValues).length === 0) {
    return null;
  }
  if (Object.keys(perValues).length > 1) {
    /* @todo warn */
  }

  return (
    <div
      style={{ textAlign: 'right', fontSize: '85%', ...style }}
      className="greyed-out"
      {...props}>
      {perDescription(...Object.entries(perValues)[0])}
    </div>
  );
}

ScorePer.propTypes = {
  rules: PropTypes.arrayOf(PropTypes.object).isRequired,
  style: PropTypes.object
};

export default ScorePer;
