const en = {
  LOADING: 'Loading',
  SIGN_UP: 'Sign up',
  LOGIN: 'Login',
  LOGOUT: 'Logout',
  EMAIL: 'Email',
  PASSWORD: 'Password',
  FORGOT_PASSWORD: 'Forgot password',
  SUBMIT: 'Submit',
  PRODUCTS: 'Products',
  BRANDS: 'Brands',
  BRAND: 'Brand',
  USAGES: 'Category',
  USAGE: 'Category',
  CERTIFICATES: 'Certificates',
  RETAILERS: 'Retailers',
  CATEGORY: 'Category',
  SUPPLIED: 'Data supplied',
  SUSTAINABILITY: 'Sustainability',
  HEALTH: 'Health',
  NUTRISCORE: 'Nutriscore',
  HEALTH_SCORE: 'Health score',
  COMPARISON_GROUPS: 'Comparison groups',
  INGREDIENTS: 'Ingredients',
  NUTRIENTS: 'Nutrients',
  ACCOUNT: 'Account',
  PROFILE: 'Profile',
  HELP: 'Help',
  SAVE: 'Save',
  NAME: 'Name',
  COMPANY_NAME: 'Organization',
  AGE: 'Last update',
  SOURCES: 'Sources',
  SOURCE: 'Source',
  POSITION: 'Role',
  DEPARTMENT: 'Department',
  ADDRESS: 'Address',
  ZIPCODE: 'Postcode',
  CITY: 'City',
  PHONE: 'Phone',
  ABOUT: 'About',
  VALID_UNTIL: 'Access until',

  HEALTH_A: 'Yes',
  HEALTH_B1: 'Reasonable',
  HEALTH_B1_LOGO: 'Reason -able',
  HEALTH_B2: 'Fair',
  HEALTH_C1: 'Poor',
  HEALTH_C2: 'Least',

  DETAILS: 'details',
  HEALTH_IMPROVEMENT_TITLE: 'Health improvement potential',
  HEALTH_IMPROVEMENT_ABSENT: 'This product has no health score yet.',
  HEALTH_IMPROVEMENT_PENDING:
    'Sorry, there is no health analysis available yet for this product.',
  HEALTH_IMPROVEMENT_MISSING: 'This product could not be given a health score.',
  HEALTH_IMPROVEMENT_STATIC:
    'Products in this category always receive this health score.',
  HEALTH_IMPROVEMENT_IN_SCHIJF_BODY_1: '{{product}} falls',
  HEALTH_IMPROVEMENT_IN_SCHIJF_BODY_2: 'within the Wheel of Five (WoF)',
  HEALTH_IMPROVEMENT_IN_SCHIJF_BODY_3: ' and receives the best health rating.',
  HEALTH_IMPROVEMENT_IN_SCHIJF_HEADING:
    'All of the following requirements are being met:',
  HEALTH_IMPROVEMENT_IN_SCHIJF_OR: 'or all of the following:',
  HEALTH_IMPROVEMENT_UIT_SCHIJF_BODY_1: '{{product}} falls',
  HEALTH_IMPROVEMENT_UIT_SCHIJF_BODY_2: 'outside the Wheel of Five',
  HEALTH_IMPROVEMENT_UIT_SCHIJF_BODY_3: ' and receives health score',
  HEALTH_IMPROVEMENT_UIT_SCHIJF_BODY_4: '.',
  HEALTH_IMPROVEMENT_UIT_SCHIJF_HEADING:
    'To fall within the Wheel of Five (WoF), all of following requirements need to be met:',
  HEALTH_IMPROVEMENT_NOOIT_SCHIJF_HEADING:
    'Products in this category always fall outside the Wheel of Five.',

  SEARCH_PLACEHOLDER: 'Search product or barcode',
  COMPARISON_NAME_PLACEHOLDER: 'Provide comparison name',

  MY_BRANDS: 'My brands',
  MY_PRODUCTS: 'My products',
  VIEW: 'View',
  CONTACT: 'Contact',
  SCORES: 'Scores',
  IMPACT: 'Impact',

  SCORE_SOCIAL: 'Human rights',
  SCORE_ANIMALS: 'Animal welfare',
  SCORE_ENVIRONMENT: 'Environment',
  SCORE_HEALTH: 'Health',
  SCORE_SUSTAINABILITY: 'Sustainability',

  NUTRIENT_CALCIUM: 'Calcium',
  NUTRIENT_PROTEIN: 'Protein',
  NUTRIENT_ENERGY: 'Energy (kJ)',
  NUTRIENT_ENERGY_THERMAL: 'Energy (kcal)',
  NUTRIENT_ENERGY_ANY: 'Energy',
  NUTRIENT_FAT_MONOUNSATURATED: 'Fat monosaturated',
  NUTRIENT_IRON: 'Iron',
  NUTRIENT_CARBOHYDRATES: 'Carbohydrates',
  NUTRIENT_LACTOSE: 'Lactose',
  NUTRIENT_FAT_POLYUNSATURATED: 'Fat polyunsaturated',
  NUTRIENT_NATRIUM: 'Sodium',
  NUTRIENT_SUGAR: 'Sugar',
  NUTRIENT_SUGAR_ANY: 'Sugar',
  NUTRIENT_SUGAR_NO_FRUITJUICE: 'Sugar',
  NUTRIENT_SUGAR_OR_FRUITJUICE: 'Sugar',
  NUTRIENT_FAT_TRANS: 'Fat trans',
  NUTRIENT_FAT_SATURATED: 'Fat saturated',
  NUTRIENT_FAT_TOTAL: 'Fat total',
  NUTRIENT_FIBER: 'Fiber',
  NUTRIENT_A: 'Vitamin A',
  NUTRIENT_B1: 'Vitamin B1',
  NUTRIENT_B12: 'Vitamin B12',
  NUTRIENT_C: 'Vitamin C',
  NUTRIENT_SALT: 'Salt',
  NO_ADDED_NUTRIENT: 'No added {{nutrient}}',
  NO_ADDED_UNHEALTHY: 'All ingredients in WoF',
  NUTRIENT_SUGAR_OR_NO_ADDED_SUGAR: 'Sugar',
  NUTRIENT_B1_OR_B12: 'Vitamin B1 or B12',
  VALUE_UNKNOWN: 'value unknown',

  NUTRIENTS_PREPARED: 'for the prepared product',
  NUTRIENTS_UNPREPARED: 'for the unprepared product',

  VALUE_PER_PORTION: '{{value}}{{unit}} per portion',
  VALUE_PER_PER: '{{value}}{{unit}} per {{per}',
  PER_PORTION_OF: 'per portion of {{portion}}',
  UNHEALTHY_EXCEPT_SS_NOTE: 'sugar and salt allowed',
  UNHEALTHY_EXCEPT_FRUITJUICE_NOTE: 'fruit juice allowed',
  OR: 'or',

  SHOW_OWNED_PRODUCTS_ONLY: 'Show my brands only',
  NO_RESULTS: 'No results',
  SELECT_OR_SEARCH: 'Select or search',
  SELECT: 'Select',
  NO_CERTIFICATES: 'No certificates',
  PRODUCER: 'Producer',
  SUPERMARKET: 'Supermarket',
  REFRIGERATION_TYPE: 'Refrigeration type',
  PACKAGING: 'Packaging',
  PAGE_NOT_FOUND: 'Page not found',
  FORBIDDEN: 'Forbidden',
  CLEAR_ALL_FILTERS: 'Clear filters, search and sorts',

  CREATE_NEW_COMPARISON: 'New comparison',
  SELECTED: 'Selected',
  ADD_TO_COMPARISON_LIST: 'Add to comparison list',
  COMPARISONS: 'Comparisons',
  EDIT_COMPARISON: 'Edit comparison',
  ADD_PRODUCT_TO_COMPARISON: 'Add product to comparison',
  NO_COMPARISON_PRODUCTS: 'This comparison has no products',

  MSG_PROFILE_UPDATED: 'Profile updated',
  MSG_COMPARISON_CREATED: 'Comparison created',
  MSG_COMPARISON_UPDATED: 'Comparison updated',
  MSG_RESET_PASSWORD_SENT: 'Please check your email for instructions',

  LANG_EN: 'English',
  LANG_NL: 'Dutch',

  LABEL: 'Label',

  SHOW_PRODUCT_AGE: 'Show product age',

  LESS_THAN_N_MONTH: {
    one: 'Less than 1 month ago',
    other: 'Less than %{count} months ago'
  },

  PACKAGE_SIZE: 'Package size',
  EXPORT_CSV: 'Export CSV',
  MSG_MUST_BE_INVITED: 'You must be invited by someone.',
  I_ACCEPT: 'I accept the',
  TERMS_CONDITIONS: 'Terms and Conditions',
  SIGN_UP_SUCCESS: 'You have signed up successfully',
  SIGN_UP_FAILURE: 'There were some issues accepting your sign up',
  MSG_CONFIRM_EXPORT:
    'The selection you made is rather large. Downloading this selection may take upto %{count} minutes. Are you sure you want to proceed?',
  SUPPORT: 'Support',
  SEARCH: 'Search',
  PLATFORM_ISSUES:
    'We are having some platform issues. Please try again after some time.',
  SEARCHING: 'Searching',
  UNVALIDATED_INFO:
    'The information on this product has been updated recently. It has not yet passed our error checks.',
  BETA_LABEL: 'beta',
  BETA_INFO: 'This feature is present as a preview and not fully tested.',

  IMAGE_NEXT: 'Next image',
  IMAGE_PREV: 'Previous image',
  IMAGE_ZOOM_IN: 'Zoom in',
  IMAGE_ZOOM_OUT: 'Zoom out',
  IMAGE_CLOSE: 'Close image',

  METABASE_NOT_AUTHORISED:
    'It looks like you do not have the required permissions to view this page',

  FILTER_SHOWING_X_ITEMS: 'Showing {{n}} items, type to search',
  FILTER_NO_RESULTS: 'No results found, try another search'
};

export default en;
