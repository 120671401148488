import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';
import Slider from 'rc-slider';
import { t } from '../../i18n';
import { DEFAULT_AGE_MAX, DEFAULT_AGE_MIN } from '../../constants';

function Handle(props) {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="top"
      key={index}>
      <Slider.Handle value={value} {...restProps} />
    </Tooltip>
  );
}

function Age(props) {
  const { value, onChange } = props;
  return (
    <div>
      <div className="flex-row middle-xs between-xs">
        <p>{t('AGE')}</p>
        <p>
          <small className="text-muted">
            {t('LESS_THAN_N_MONTH', { count: value })}
          </small>
        </p>
      </div>
      <Slider
        min={DEFAULT_AGE_MIN / 30}
        max={DEFAULT_AGE_MAX / 30}
        handle={Handle}
        onChangeComplete={onChange}
        defaultValue={value}
      />
    </div>
  );
}

Handle.propTypes = {
  value: PropTypes.number,
  dragging: PropTypes.bool,
  index: PropTypes.number
};

Age.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func
};

export default Age;
