export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const FETCH_PRODUCTS_REQUEST = `${FETCH_PRODUCTS}_REQUEST`;
export const FETCH_PRODUCTS_SUCCESS = `${FETCH_PRODUCTS}_SUCCESS`;
export const FETCH_PRODUCTS_FAILURE = `${FETCH_PRODUCTS}_FAILURE`;

export const FETCH_PRODUCT = 'FETCH_PRODUCT';
export const FETCH_PRODUCT_REQUEST = `${FETCH_PRODUCT}_REQUEST`;
export const FETCH_PRODUCT_SUCCESS = `${FETCH_PRODUCT}_SUCCESS`;
export const FETCH_PRODUCT_FAILURE = `${FETCH_PRODUCT}_FAILURE`;

export const FETCH_SCORING_REPORT = 'FETCH_SCORING_REPORT';
export const FETCH_SCORING_REPORT_REQUEST = `${FETCH_SCORING_REPORT}_REQUEST`;
export const FETCH_SCORING_REPORT_SUCCESS = `${FETCH_SCORING_REPORT}_SUCCESS`;
export const FETCH_SCORING_REPORT_FAILURE = `${FETCH_SCORING_REPORT}_FAILURE`;

export const EXPORT_CSV = 'EXPORT_CSV';

export function fetchProducts(params) {
  return { type: FETCH_PRODUCTS, ...params };
}

export function fetchProduct(params) {
  return { type: FETCH_PRODUCT, ...params };
}

export function fetchScoringReport(id) {
  return { type: FETCH_SCORING_REPORT, id };
}

export function exportCsv(params) {
  return { type: EXPORT_CSV, ...params };
}
