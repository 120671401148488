import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select, { components } from 'react-select';
import LocalStore from 'store';
import { isEqual, omit, uniqBy } from 'lodash/fp';

import { t } from '../../i18n';
import { addQuery, removeQuery, getQuery } from '../../lib/query-params';

/**
 * Todo: Make the component design and composability better
 */

class Filter extends Component {
  constructor(props) {
    super(props);
    this.update = this.update.bind(this);
    this.search = this.search.bind(this);
    // To remember the selected items while searching
    this.state = {
      selected: getStoredData(props.attr) || [],
      keyword: ''
    };
  }

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps) {
    this.append();

    // If query has changed, fetch
    const ignore = ['columns', 'page', 'sort_key', 'sort_order'];
    if (isEqual(omit(ignore)(prevProps.query))(omit(ignore)(this.props.query)))
      return;
    this.fetch();
  }

  fetch() {
    const { dispatch, fetcher, query } = this.props;
    // We are passing params here to filter out data
    const { page, ...params } = query;
    if (fetcher) dispatch(fetcher(params));
  }

  render() {
    const {
      items,
      total,
      loading,
      value,
      title,
      multi,
      searchable,
      disabled,
      attr,
      query
    } = this.props;
    const { selected, keyword } = this.state;

    let _data = items
      .concat(selected)
      .map(({ name, id }) => ({ name, id: id.toString() }));

    // when there is a selected item, append it
    if (selected.length > 0) {
      _data = selected.concat(_data);
    }

    let noResultsText = '';

    // Set text for when there are no results
    if (loading) {
      noResultsText = t('SEARCHING') + '...';
    } else if (keyword.length > 0) {
      noResultsText = t('NO_RESULTS');
    }

    if (query[attr]) {
      const appended = items.filter((i) =>
        query[attr].includes(i.id.toString())
      );
      _data = _data.concat(appended);
    }

    // make sure there's unique data
    const data = uniqBy('id')(_data);

    const _value = data.filter((x) => (value || []).includes(x.id));

    const Menu = (props) => {
      const { options } = props;
      return (
        <>
          <components.Menu {...props}>{props.children}</components.Menu>
          {!loading && (
            <div
              className="small text-muted"
              style={{ paddingLeft: 10, paddingTop: 5 }}>
              {options.length > 0 && total > 0 && (
                <>{t('FILTER_SHOWING_X_ITEMS', { n: options.length })}</>
              )}
              {!total && keyword && <>{t('FILTER_NO_RESULTS')}</>}
            </div>
          )}
        </>
      );
    };

    return (
      <div>
        {t(title)} <small className="text-muted">({total})</small>
        <Select
          isMulti={multi}
          isSearchable={searchable}
          value={_value}
          options={data}
          onChange={this.update}
          onInputChange={this.search}
          isLoading={loading}
          isDisabled={disabled}
          getOptionLabel={(o) => o.name}
          getOptionValue={(o) => o.id}
          noResultsText={noResultsText}
          placeholder={searchable ? t('SEARCH') : t('SELECT')}
          components={searchable ? { Menu } : {}}
        />
      </div>
    );
  }

  update(arr) {
    const ids = arr.map((i) => i.id);
    const { multi, attr, items } = this.props;
    const value = multi ? ids : ids.join(',');
    const singleSelect = (p) => value == p.id;
    const multiSelect = (p) => value.find((i) => i == p.id);
    const isMulti = Array.isArray(value);
    const selected = items
      .concat(this.state.selected)
      .filter(isMulti ? multiSelect : singleSelect);

    // Populate selected array (similar to items)
    // We need to concat here because the previously selected items
    // also need to be remembered
    this.setState({ selected });

    // We also remember this in localstorage
    LocalStore.set(`filter_${attr}`, selected);

    if (!ids) removeQuery(attr, 'page');
    else addQuery({ [attr]: ids, page: 1 });
  }

  search(keyword, { action }) {
    if (!['input-change', 'input-blur'].includes(action)) return;
    this.setState({ keyword });
    const { dispatch, fetcher, searchKey, query } = this.props;
    const { page, ...params } = query;
    if (!fetcher) return;
    if (!keyword) {
      if (this.state.keyword !== keyword || this.props.total === 0) {
        dispatch(fetcher(params));
      }
      return;
    }
    dispatch(fetcher({ [searchKey]: keyword, ...params }));
  }

  append() {
    const { items, value, loading, appender, dispatch } = this.props;
    if (loading) return; // check after request is done
    // Here we are checking if the value provided already exists in the store
    // If not, then we are making a request to fetch it and append it in the
    // store.
    const flat = items.concat(this.state.selected).map((i) => i.id);
    const current = value || [];
    const ids = current.filter((id) => !flat.includes(id));
    // we have all the items in the items array
    if (!ids.length || !appender) return;
    // fetch and append the ones that are missing
    dispatch(appender({ ids }));
  }
}

Filter.propTypes = {
  fetcher: PropTypes.func,
  appender: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  attr: PropTypes.string.isRequired,
  value: PropTypes.array,
  stateName: PropTypes.string,
  searchKey: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  multi: PropTypes.bool,
  searchable: PropTypes.bool,
  disabled: PropTypes.bool,
  query: PropTypes.object
};

Filter.defaultProps = {
  searchable: true,
  multi: true,
  disabled: false,
  loading: false
};

function select(state, props) {
  return {
    ...state[props.stateName],
    query: getQuery()
  };
}

export default connect(select)(Filter);

function getStoredData(attr) {
  const params = getQuery();
  const stored = LocalStore.get(`filter_${attr}`) || [];
  // Return only the ones that exist in the query param
  return stored.filter(
    (item) => params[attr] && params[attr].includes(item.id.toString())
  );
}
