import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import isEqual from 'lodash/fp/isEqual';
import { isOwned } from '../../lib/helpers';
import d3Tip from 'd3-tip';

// Tooltip
const tip = d3Tip()
  .attr('class', 'd3-tip')
  .offset([-10, 0])
  .html((d) => d.name);

class Nutrient extends Component {
  componentDidMount() {
    this.create(this.props);
  }

  componentDidUpdate(prevProps) {
    if (isEqual(prevProps)(this.props)) return;
    this.update(this.props);
  }

  componentWillUnmount() {
    d3.selectAll('.d3-tip').remove();
  }

  render() {
    return <svg ref={(svg) => (this.svg = svg)} width={700} height={320}></svg>;
  }

  create({ nutrient }) {
    const { data } = this.props;
    if (!data.length) return;
    const { x, y, g } = create(this.svg);
    this.setState({ x, y, g });
    update(x, y, g, this.svg, data, nutrient);
  }

  update({ nutrient }) {
    const { x, y, g } = this.state;
    const { data } = this.props;
    if (!data.length) return;
    update(x, y, g, this.svg, data, nutrient);
  }
}

Nutrient.propTypes = {
  nutrient: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired
};

export default Nutrient;

const margin = { top: 50, right: 50, bottom: 40, left: 200 };

/**
 * Create svg and container
 */

function create(_svg) {
  const { width, height } = attrs(_svg);
  const svg = d3.select(_svg);
  const g = svg
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('class', 'container')
    .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
  g.call(tip);

  // define scales
  const x = d3.scaleLinear().range([0, width]);
  const y = d3.scaleBand().range([height, 0]).padding(0.3);

  return { x, y, g };
}

/**
 * Get svg height and width attributes
 */

function attrs(_svg) {
  const svg = d3.select(_svg);
  const width = svg.attr('width') - margin.left - margin.right;
  const height = svg.attr('height') - margin.top - margin.bottom;
  return { width, height };
}

/**
 * Update nodes with data
 */

function update(x, y, g, _svg, data, nutrient) {
  // set height of the graph according to amount of data
  const _height = data.length > 10 ? 30 * data.length : 320;
  d3.select(_svg).attr('height', _height);

  const { width, height } = attrs(_svg);
  y.range([height, 0]);

  // apply transition
  const t = d3.transition().duration(400).ease(d3.easeLinear);

  // In order to allow duplicate values in y - axis
  // https://stackoverflow.com/a/40695022/232619
  // Maintain a map of id and brand name
  // { 1: 'AH', 2: 'Ekoplaza' };
  const mapIdBrand = data.reduce((r, p) => ({ ...r, [p.id]: p.brand }), {});

  // define domains based on data
  x.domain([0, d3.max(data, (d) => d.value)]);
  y.domain(data.map((d) => d.id));

  // x and y axis
  g.call(xAxis);
  g.call(yAxis);

  function xAxis(g) {
    g.select('.x-axis').remove(); // remove before adding (for update op)
    g.append('g')
      .attr('class', 'x-axis')
      .attr('transform', 'translate(0,' + height + ')')
      .call(d3.axisBottom(x).ticks(4))
      .append('text')
      .attr('y', 30)
      .attr('x', width / 2)
      .attr('dy', '0.5em')
      .style('fill', 'black')
      .text(nutrient.unit);
  }

  function yAxis(g) {
    g.select('.y-axis').remove(); // remove before adding (for update op)
    g.append('g')
      .attr('class', 'y-axis')
      .call(d3.axisLeft(y).tickFormat((d) => mapIdBrand[d]));
  }

  // append rects to svg based on data
  const bars = g
    .selectAll('.bar')
    .remove()
    .exit()
    .data(data)
    .enter()
    .append('rect')
    .attr('class', (d) => (isOwned(d.brand_id) ? 'owned bar' : 'bar'))
    .attr('x', 1)
    .attr('y', (d) => y(d.id));

  bars
    .attr('height', y.bandwidth())
    .transition(t)
    .attr('width', (d) => x(d.value || 0));

  let focused = false;
  bars
    .on('mouseover', (event, data) => {
      if (focused) return;
      tip.show(data, event.target);
    })
    .on('mouseout', (event) => {
      if (focused) return;
      tip.hide(event.target);
    })
    .on('focus', (event, data) => {
      focused = true;
      tip.show(data, event.target);
    })
    .on('blur', (event) => {
      focused = false;
      tip.hide(event.target);
    });

  // append values at the end of the rect
  g.selectAll('.value')
    .remove()
    .exit()
    .data(data)
    .enter()
    .append('text')
    .attr('class', 'value')
    .attr('x', (d) => x(d.value || 0) + 10)
    .attr('y', (d) => y(d.id))
    .attr('dy', y.bandwidth() / 2 + 5) // it seems change y position
    .text((d) => val(d.value));
}

/**
 * Add precision to floating point values
 */

function val(value) {
  return value.toString().includes('.') ? value.toFixed(1) : value;
}
