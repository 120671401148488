import React from 'react';
import PropTypes from 'prop-types';
import { scaleLinear } from 'd3';
import Tooltip from './ScoreTooltip';

/*
 * SVG marker definition
 *
 * Some markers are directional, in that case {dirPostFix} chooses which.
 *
 * @see defs
 */
function svgMarker(marker, dirPostFix) {
  if (!marker || marker === 'none') {
    return 'none';
  }
  if (marker === 'arrow') {
    return `url(#${marker}${dirPostFix})`;
  } else {
    return `url(#${marker})`;
  }
}

/*
 * SVG component for displaying a score scale (spanning multiple cells)
 *
 * @see ScoreTable
 */
function ScoreScale({
  boundaries,
  value,
  cellWidth,
  height,
  valueProps,
  margin,
  tooltip,
  ...props
}) {
  const textY = height * 0.5 - margin / 2;
  const lineY = height * 0.5 + margin / 2;
  const scaleX = scaleLinear()
    .domain(boundaries.map((r) => r.value))
    .range(boundaries.map((_, i) => i * cellWidth))
    .clamp(true);
  return (
    <g {...props} className="score-scale-linear">
      {/* horizontal line with nodes for each score */}
      <path
        d={boundaries
          .map((r, i) => ` ${i === 0 ? 'M' : 'L'}${scaleX(r.value)},${lineY}`)
          .join(' ')}
        style={styles.line}
        markerStart={svgMarker(boundaries[0].marker || 'butt', 'Start')}
        markerMid={svgMarker('butt')}
        markerEnd={svgMarker(
          boundaries[boundaries.length - 1].marker || 'butt',
          'End'
        )}
      />
      {/* axis labels */}
      {boundaries.map(({ value, label }, i) => (
        <text
          key={label}
          x={scaleX(value)}
          y={textY}
          dx={i === 0 ? -2 : 0}
          style={{
            ...styles.axisLabel,
            textAnchor:
              i === 0 ? 'start' : i <= boundaries.length ? 'middle' : 'end'
          }}>
          {label}
        </text>
      ))}
      {/* the value dot */}
      {value || value === 0 ? (
        <Tooltip message={tooltip}>
          <circle cx={scaleX(value)} cy={lineY} r={7} {...valueProps} />
        </Tooltip>
      ) : null}
    </g>
  );
}

ScoreScale.defaultProps = {
  margin: 12,
  cellWidth: 150,
  valueProps: {}
};

ScoreScale.propTypes = {
  boundaries: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.number,
  margin: PropTypes.number.isRequired,
  cellWidth: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  tooltip: PropTypes.string,
  valueProps: PropTypes.object.isRequired
};

const styles = {
  line: {
    stroke: 'black',
    strokeWidth: 1
  },
  axisLabel: {
    fontSize: 11
  }
};

// SVG definitions to import for markers to work
export const defs = (
  <defs>
    <marker
      id="butt"
      refX={0.75}
      refY={4}
      markerWidth={3}
      markerHeight={8}
      markerUnits="strokeWidth"
      orient="auto">
      <path d="M0,0 L0,8 L1.5,8 L1.5,0 z" />
    </marker>
    <marker
      id="arrowEnd"
      refX={4}
      refY={4}
      markerWidth={8}
      markerHeight={8}
      markerUnits="strokeWidth"
      orient="auto">
      <path d="M0,0 L0,8 L8,4 z" />
    </marker>
    <marker
      id="arrowStart"
      refX={4}
      refY={4}
      markerWidth={8}
      markerHeight={8}
      markerUnits="strokeWidth"
      orient="auto">
      <path d="M0,4 L8,0 L8,8 z" />
    </marker>
  </defs>
);
ScoreScale.defs = defs;

export default ScoreScale;
