import { addQuery } from './query-params';

export function getCurrentSort(props) {
  const { default_sort_key, default_sort_order } = props;
  const { sort_key, sort_order } = props.query;
  return (
    (sort_key || default_sort_key) + ' ' + (sort_order || default_sort_order)
  );
}

export function sort(key, current, order = 'desc') {
  const [sort_key, sort_order] = current.split(' ');
  if (key === sort_key) {
    addQuery({ sort_key: key, sort_order: reverse(sort_order), page: 1 });
  } else {
    addQuery({ sort_key: key, sort_order: order, page: 1 });
  }
}

function reverse(order) {
  return order === 'asc' ? 'desc' : 'asc';
}

export function sortClass(key, current, rev = false) {
  const [sort_key, sort_order] = current.split(' ');
  if (key !== sort_key) return 'sortable';
  const order = rev ? reverse(sort_order) : sort_order;
  return 'sortable active ' + order;
}
