import React from 'react';
import PropTypes from 'prop-types';
import { HEALTH_SCORES, HEALTH_SCORE_COLORS } from '../../constants';
import { t } from '../../i18n';

function HealthScore({ score, ...rest }) {
  if (!score) return null;
  const text = t(`HEALTH_${score}_LOGO`, {
    defaultValue: t(`HEALTH_${score}`)
  });
  const words = text.split(' ');
  let content = words[0];
  if (words.length > 1) {
    content = words.map((word, index) => (
      <tspan y={45 + 15 * index} x={50} key={index}>
        {word}
      </tspan>
    ));
  }
  return (
    <div {...rest} style={{ maxWidth: 50, maxHeight: 50 }} title={text}>
      <svg width="100%" height="100%" viewBox="0 0 100 100">
        <circle
          cx="50%"
          cy="50%"
          r="40"
          stroke={HEALTH_SCORE_COLORS[score]}
          strokeWidth="12"
          fill="white"
        />
        <text
          x="50%"
          y="55%"
          textAnchor="middle"
          fontSize="18"
          fontWeight="500"
          color="black">
          {content}
        </text>
      </svg>
    </div>
  );
}

HealthScore.propTypes = {
  score: PropTypes.oneOf(HEALTH_SCORES)
};

export default HealthScore;
