import React from 'react';
import PropTypes from 'prop-types';

import HealthScore from '../HealthScore';
import Nutriscore from '../Nutriscore';
import { t } from '../../i18n';

function ProductScores(props) {
  const { nutriscore, health_score } = props;
  const style = { marginBottom: 5, alignItems: 'center' };
  return (
    <div>
      <div className="flex-row between-xs" style={style}>
        <span>{t('SCORE_HEALTH')}</span> <HealthScore score={health_score} />
      </div>
      {nutriscore && (
        <div
          className="flex-row between-xs"
          style={{ paddingTop: 8, ...style }}>
          <span>{t('NUTRISCORE')}</span> <Nutriscore score={nutriscore} />
        </div>
      )}
    </div>
  );
}

ProductScores.propTypes = {
  // sustainability_score: PropTypes.number,
  // environment_score: PropTypes.number,
  // social_score: PropTypes.number,
  // animals_score: PropTypes.number,
  health_score: PropTypes.string
};

export default ProductScores;
