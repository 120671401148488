export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const NOTIFY = 'NOTIFY';

export function changeLanguage(lang) {
  return { type: CHANGE_LANGUAGE, lang };
}

export function notify(params) {
  return { type: NOTIFY, ...params };
}
