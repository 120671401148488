import {
  SEARCH_REQUEST,
  SEARCH_SUCCESS,
  SEARCH_FAILURE
} from '../actions/search';
import product from './product';

const initialState = {
  loading: false,
  items: [],
  total: 0
};

export default function search(state = initialState, action) {
  switch (action.type) {
    case SEARCH_REQUEST:
      return {
        ...state,
        items: [],
        loading: true
      };
    case SEARCH_SUCCESS:
      return {
        ...state,
        total: action.payload.total,
        items: action.payload.products.map(product),
        loading: false
      };
    case SEARCH_FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
