import { t } from '../../i18n';

export function nutrientName(c) {
  if (c === 'energy') c = 'energy_any'; // don't include '(kJ)'
  return t('NUTRIENT_' + c.toUpperCase(), { _: c });
}

export function nutrientNameEx(c) {
  if (c === 'added_unhealthy' || c.startsWith('added_unhealthy_except_')) {
    return t('NO_ADDED_UNHEALTHY');
  } else if (c === 'added_sugar_or_fruitjuice_or_sugar') {
    // old-style or-rules that we want to show differently
    return t('NUTRIENT_SUGAR_OR_NO_ADDED_SUGAR');
  } else if (c && c.startsWith('added_')) {
    return t('NO_ADDED_NUTRIENT', {
      nutrient: nutrientName(c.substr(6)).toLowerCase()
    });
  } else if (c && c.includes('_vs_')) {
    return c
      .split('_vs_')
      .map((s) => nutrientName(s))
      .join(' vs. ');
  } else {
    return nutrientName(c);
  }
}

// Return text for info-icon after nutrient name
export function nutrientInfoTooltip(c) {
  if (c === 'added_unhealthy_except_ss') {
    return t('UNHEALTHY_EXCEPT_SS_NOTE');
  } else if (c === 'added_unhealthy_except_fruitjuice') {
    return t('UNHEALTHY_EXCEPT_FRUITJUICE_NOTE');
  }
}

// Return personal health scoring object's subscores
export function subscores(health_report) {
  return (health_report || {}).children || [];
}

// Return personal health rules for a score
export function subscoreRules(health_report, score) {
  return (
    (subscores(health_report).find((s) => s.id === score) || {}).children || []
  );
}

// Return personal health rules for multiple scores
export function multipleSubscoreRules(health_report, scores) {
  return scores.reduce(
    (r, score) => r.concat(subscoreRules(health_report, score)),
    []
  );
}

// Return rule by id for a score
export function subscoreRule(health_report, score, id) {
  return subscoreRules(health_report, score).find((s) => s.id === id) || {};
}

// Return whether the personal health scoring object has just one static rule or not
export function hasSingleStaticRule(health_report) {
  const scores = subscores(health_report);
  if (scores.length !== 1) {
    return false;
  }
  const rules = scores[0].children || [];
  if (rules.length !== 1) {
    return false;
  }
  return rules[0].id === 'static';
  // the following may be nicer, but it doesn't work for old scoring reports without type
  // return (rules[0].rule || {}).type === 'static_rule';
}

export function scoreTooltip(rule, rule_input) {
  const type = (rule || {}).type;
  const value = (rule_input || {}).value;
  const displayValue =
    value && (value > 10 ? value.toFixed() : value.toPrecision(2));
  if (rule.type === 'or_rule') {
    return null;
  } else if (!rule_input || value === null) {
    return t('VALUE_UNKNOWN');
  } else if (type === 'nutrient_rule') {
    if (rule.per === 'portion') {
      return t('VALUE_PER_PORTION', { value: displayValue, unit: rule.unit });
    } else {
      return t('VALUE_PER_PER', {
        value: displayValue,
        unit: rule.unit,
        per: rule.per
      });
    }
  } else if (type === 'nutrient_comparison_rule') {
    return `${displayValue}%`;
  } else if (
    type === 'ingredient_absence_rule' &&
    ((rule_input || {}).present_ingredients || []).length > 0
  ) {
    return rule_input.present_ingredients.join('; ');
  }
}

export function scoreValueMark(value) {
  return value === null ? '?' : value ? '✔' : '⨯';
}
