import React from 'react';
import PropTypes from 'prop-types';
import { HEALTH_SCORES } from '../../constants';
import { t } from '../../i18n';

import Filter from './Filter';

const items = HEALTH_SCORES.map((s) => ({ name: t(`HEALTH_${s}`), id: s }));

export default function Health(props) {
  return (
    <Filter
      value={props.value}
      title="HEALTH"
      items={items}
      total={items.length}
      attr="personal_health_scores"
      searchable={false}
    />
  );
}

Health.propTypes = {
  value: PropTypes.array
};
