import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Row, Grid, Checkbox, Button } from 'react-bootstrap';
import { isEqual, omit } from 'lodash/fp';
import LocalStore from 'store';

import { DEFAULT_AGE } from '../../constants';
import { t } from '../../i18n';
import { addQuery, removeQuery } from '../../lib/query-params';
import { getCurrentSort } from '../../lib/sort';
import { fetchProducts, exportCsv } from '../../actions/products';
import { fetchNutrients } from '../../actions/nutrients';
import {
  createComparison,
  updateComparison,
  fetchComparisons
} from '../../actions/comparisons';

import ProductsList from '../../components/ProductsList';
import Paginate from '../../components/Paginate';
import Loader from '../../components/Loader';
import NoResults from '../../components/NoResults';
import Filter from '../../components/Filter';
import Selector from '../../components/Selector';
import Comparisons from '../../components/Comparisons';
import Toolbar from '../../components/Toolbar';
import Beta from '../../components/Beta';

class Products extends Component {
  constructor(props) {
    super(props);
    this.showOwnedProductsOnly = this.showOwnedProductsOnly.bind(this);
    this.onColumnSelect = this.onColumnSelect.bind(this);
    this.onRowSelect = this.onRowSelect.bind(this);
    this.createComparison = this.createComparison.bind(this);
    this.onAgeChange = this.onAgeChange.bind(this);
    this.exportCsv = this.exportCsv.bind(this);
    this.state = {
      selected: [],
      showAge: false,
      showNutriscore: false,
      showHealthScore: false
    };
  }

  componentDidMount() {
    this.requestProducts();
    this.props.dispatch(fetchNutrients({ per_page: 30 }));
    this.props.dispatch(fetchComparisons());
  }

  componentDidUpdate(prevProps) {
    // Fetch if the query params have changed
    const ignore = ['columns'];
    if (isEqual(omit(ignore)(prevProps.query))(omit(ignore)(this.props.query)))
      return;
    this.requestProducts();
  }

  componentWillUnmount() {
    this.rememberFilters();
  }

  requestProducts() {
    const sort = getCurrentSort(this.props);
    const confirmed_age_max = this.props.age;
    this.props.dispatch(
      fetchProducts({ ...this.props.query, sort, confirmed_age_max })
    );
  }

  render() {
    const {
      loading,
      total,
      owned_brand_ids,
      query,
      nutrients,
      items,
      comparisons,
      age
    } = this.props;
    const { selected, showAge, showNutriscore, showHealthScore } = this.state;
    const selectable = !!selected.length;
    const {
      brand_ids,
      usage_ids,
      certificate_ids,
      page,
      retailer_ids,
      columns,
      package_size_min,
      package_size_max,
      package_size_unit,
      nutriscores,
      personal_health_scores
    } = query;
    const owned = 'owned' in query;
    const clearable = this.isClearable();

    const CsvDownloadBtn = (
      <Button onClick={this.exportCsv}>{t('EXPORT_CSV')}</Button>
    );

    const AddToComparisons = (
      <div className="flex-row middle-xs">
        <Loader loading={comparisons.loading} text=" " />
        &nbsp;
        <Comparisons.AddEdit
          selected={selected}
          onCreate={this.createComparison}>
          {t('CREATE_NEW_COMPARISON')}
        </Comparisons.AddEdit>
        &nbsp;
        <Comparisons.Existing
          comparisons={comparisons.items}
          onClick={(c) => this.addToExisting(c)}
        />
        &nbsp;
        <Beta />
      </div>
    );
    const SelectColumns = (
      <div className="flex-row middle-xs">
        <Checkbox
          inline
          checked={showAge}
          onChange={() => this.toggleState('showAge')}>
          {t('AGE')}
        </Checkbox>
        &nbsp;
        <Checkbox
          inline
          checked={showNutriscore}
          onChange={() => this.toggleState('showNutriscore')}>
          {t('NUTRISCORE')}
        </Checkbox>
        &nbsp;
        <Checkbox
          inline
          checked={showHealthScore}
          onChange={() => this.toggleState('showHealthScore')}>
          {t('HEALTH_SCORE')}
        </Checkbox>
        &nbsp;
        <Selector
          selected={columns}
          columns={nutrients.map((n) => ({
            ...n,
            name: t(`NUTRIENT_${n.code.toUpperCase()}`)
          }))}
          accessor="code"
          onSelect={this.onColumnSelect}
          pullRight>
          {t('NUTRIENTS')}
        </Selector>
        &nbsp;
        {CsvDownloadBtn}
      </div>
    );

    const ActionItem = selectable ? AddToComparisons : SelectColumns;
    const Title = selectable ? (
      <h4>
        {selected.length} {t('SELECTED')}
      </h4>
    ) : (
      <h2>
        {t('PRODUCTS')} <small>({total})</small>
      </h2>
    );

    return (
      <Grid fluid>
        <Row>
          <Col xs={12} sm={3} style={{ position: 'sticky', top: 0 }}>
            <br />
            <Filter.Usages value={usage_ids} />
            <br />
            <Filter.Brands value={brand_ids} disabled={owned} />
            <br />
            <Checkbox checked={owned} onChange={this.showOwnedProductsOnly}>
              {t('SHOW_OWNED_PRODUCTS_ONLY')}
            </Checkbox>
            <br />
            <Filter.Nutriscore value={nutriscores} />
            <br />
            <Filter.Health value={personal_health_scores} />
            <br />
            <Filter.Age
              value={parseInt(age / 30)}
              onChange={this.onAgeChange}
            />
            <br />
            <Filter.Certificates value={certificate_ids} />
            <br />
            <Filter.Retailers value={retailer_ids} />
            <br />
            <Filter.PackageSize
              min={package_size_min}
              max={package_size_max}
              unit={package_size_unit}
              onChange={(key, val) => this.onPackageSizeChange(key, val)}
            />
          </Col>

          <Col xs={12} sm={9} className="products-container">
            {clearable && (
              <p>
                <br />
                <a onClick={(e) => this.clearAll(e)}>
                  <i className="glyphicon glyphicon-remove"></i>{' '}
                  {t('CLEAR_ALL_FILTERS')}
                </a>
              </p>
            )}

            <Toolbar right={ActionItem} active={selectable}>
              {Title}
            </Toolbar>

            <ProductsList
              showAge={showAge}
              showNutriscore={showNutriscore}
              showHealthScore={showHealthScore}
              items={items}
              owned_brand_ids={owned_brand_ids}
              sticky
              sort={getCurrentSort(this.props)}
              columns={columns.split(',')}
              onSelect={this.onRowSelect}
            />
            {!loading && !items.length && <NoResults center />}
            <Loader loading={loading} center padded />
            <Paginate current={parseInt(page, 10) || 1} total={total} />
          </Col>
        </Row>
      </Grid>
    );
  }

  showOwnedProductsOnly() {
    if ('owned' in this.props.query) removeQuery('owned');
    else addQuery({ owned: true, page: 1 });
  }

  clearAll(e) {
    e.preventDefault();
    this.setState({
      selected: [],
      showAge: false,
      showNutriscore: false,
      showHealthScore: false
    });
    removeQuery.apply(null, Object.keys(this.props.query));
  }

  onColumnSelect(col) {
    const current = (this.props.query.columns || '')
      .split(',')
      .filter((c) => c);
    let { sort_key, sort_order } = this.props.query;
    // remove if selected, otherwise include
    const columns = current.includes(col.code)
      ? current.filter((c) => c !== col.code).join(',')
      : current.concat([col.code]).join(',');

    // remove sort_key and sort_order if a column was removed on which it was
    // sorted on
    if (sort_key && sort_key.includes(col.code)) {
      sort_key = undefined;
      sort_order = undefined;
    }

    this.rememberFilters(columns);
    addQuery({ columns, sort_key, sort_order });
  }

  onRowSelect(item) {
    if (this.state.selected.map((p) => p.id).includes(item.id)) {
      // remove by filtering
      this.setState({
        selected: this.state.selected.filter((p) => p.id !== item.id)
      });
    } else {
      // add by concatenating
      this.setState({
        selected: this.state.selected.concat([item])
      });
    }
  }

  createComparison(name, selected) {
    this.props.dispatch(createComparison(name, selected));
  }

  addToExisting(c) {
    this.props.dispatch(updateComparison(c.name, this.state.selected, c.id));
  }

  rememberFilters(columns) {
    const { query } = this.props;
    if (columns) query.columns = columns;
    LocalStore.set('filters', query);
  }

  toggleState(key) {
    this.setState({ [key]: !this.state[key] });
  }

  onAgeChange(month) {
    // Since confirmed_age_max takes days, convert it to months
    const age = month * 30;
    addQuery({ confirmed_age_max: age });
  }

  onPackageSizeChange(key, val) {
    addQuery({ [key]: val });
  }

  exportCsv() {
    const { total } = this.props;
    const warn = total > 3000;
    // display rough download time
    // > 8k  3-5m
    // > 20k 15-20m
    const count = total > 20000 ? '15-20' : total > 8000 ? '3-5' : '1';
    if (warn && !window.confirm(t('MSG_CONFIRM_EXPORT', { count }))) return;
    const sort = getCurrentSort(this.props);
    const confirmed_age_max = this.props.age;
    this.props.dispatch(
      exportCsv({ ...this.props.query, confirmed_age_max, sort })
    );
  }

  isClearable() {
    return (
      !!Object.keys(this.props.query).filter(
        (k) => !['columns', 'page'].includes(k)
      ).length > 0
    );
  }
}

Products.propTypes = {
  total: PropTypes.number.isRequired,
  items: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  owned_brand_ids: PropTypes.array.isRequired,
  default_sort_key: PropTypes.string.isRequired,
  default_sort_order: PropTypes.string.isRequired,
  nutrients: PropTypes.array.isRequired,
  comparisons: PropTypes.object.isRequired,
  age: PropTypes.number,
  query: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired
};

function select(state, props) {
  const filters = LocalStore.get('filters') || {};
  const { query } = props.location;
  // add default columns here
  if (('columns' in query && !query.columns) || !('columns' in query)) {
    query.columns = filters.columns || 'salt,sugar,fat_saturated,energy';
  }

  return {
    ...state.products,
    nutrients: state.nutrients.items,
    owned_brand_ids: state.user.owned_brands.map((i) => i.id),
    comparisons: state.comparisons,
    age: parseInt(query.confirmed_age_max || DEFAULT_AGE),
    query
  };
}

export default connect(select)(Products);
