import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, FormGroup, FormControl } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import browserHistory from '../../lib/history';

import ButtonWithLoader from '../../components/ButtonWithLoader';

import { t } from '../../i18n';
import { resetPassword } from '../../actions/user';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
    this.state = {
      email: ''
    };
  }

  componentDidUpdate() {
    // if the user is logged in, redirect
    if (this.props.loggedIn) browserHistory.push('/');
  }

  render() {
    const { email } = this.state;
    const { loading } = this.props;
    return (
      <Form className="form-auth">
        <h2>{t('FORGOT_PASSWORD')}</h2>
        <FormGroup bsSize="large">
          <FormControl
            name="email"
            type="email"
            placeholder={t('EMAIL')}
            onChange={this.handleChange}
          />
        </FormGroup>
        <p>
          <Link to="/login">{t('LOGIN')}</Link>
        </p>

        <ButtonWithLoader
          type="submit"
          disabled={!email}
          onClick={this.submit}
          bsSize="large"
          bsStyle="primary"
          loading={loading}>
          {t('SUBMIT')}
        </ButtonWithLoader>
      </Form>
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value }); // update state with changed values
  }

  submit(e) {
    const { email } = this.state;
    e.preventDefault();
    this.props.dispatch(resetPassword(email));
  }
}

ResetPassword.propTypes = {
  dispatch: PropTypes.func.isRequired,
  loggedIn: PropTypes.bool,
  loading: PropTypes.bool
};

function select(state) {
  const { loggedIn, loading } = state.user;
  return {
    loggedIn,
    loading
  };
}

export default connect(select)(ResetPassword);
