import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Col,
  Grid
} from 'react-bootstrap';

import Field from '../../components/Field';
import ButtonWithLoader from '../../components/ButtonWithLoader';
import Toolbar from '../../components/Toolbar';

import { t } from '../../i18n';
import { updateProfile } from '../../actions/user';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.save = this.save.bind(this);
    this.handleChange = this.handleChange.bind(this);
    const {
      name,
      email,
      company_name,
      position,
      department,
      address,
      zipcode,
      city,
      phone
    } = props;
    this.state = {
      name,
      email,
      company_name,
      position,
      department,
      address,
      zipcode,
      city,
      phone
    };
  }

  render() {
    const {
      owned_brands,
      plan: { valid_until, retailers },
      loading
    } = this.props;
    const {
      name,
      email,
      phone,
      company_name,
      position,
      department,
      address,
      zipcode,
      city
    } = this.state;
    const enabled =
      name && email && phone && company_name && position && department;
    return (
      <Grid>
        <Toolbar>
          <h2>{t('PROFILE')}</h2>
        </Toolbar>
        <Form horizontal>
          <FormGroup controlId="brands">
            <Col componentClass={ControlLabel} sm={2}>
              {t('BRANDS')}
            </Col>
            <Col sm={4}>
              <FormControl.Static>
                {owned_brands.map((b) => b.name).join(', ')}
              </FormControl.Static>
            </Col>
          </FormGroup>

          {valid_until && (
            <FormGroup controlId="valid">
              <Col componentClass={ControlLabel} sm={2}>
                {t('VALID_UNTIL')}
              </Col>
              <Col sm={4}>
                <FormControl.Static>{valid_until}</FormControl.Static>
              </Col>
            </FormGroup>
          )}

          {retailers && (
            <FormGroup controlId="retailers">
              <Col componentClass={ControlLabel} sm={2}>
                {t('RETAILERS')}
              </Col>
              <Col sm={4}>
                <FormControl.Static>
                  {retailers.map((r) => r.name).join(', ')}
                </FormControl.Static>
              </Col>
            </FormGroup>
          )}

          <Field
            label={t('NAME')}
            name="name"
            value={name}
            onChange={this.handleChange}
            required
            dirty
          />

          <Field
            label={t('EMAIL')}
            name="email"
            value={email}
            onChange={this.handleChange}
            type="email"
            required
            dirty
          />

          <Field
            label={t('PHONE')}
            name="phone"
            value={phone}
            onChange={this.handleChange}
            required
            dirty
          />

          <Field
            label={t('COMPANY_NAME')}
            name="company_name"
            value={company_name}
            onChange={this.handleChange}
            required
            dirty
          />

          <Field
            label={t('POSITION')}
            name="position"
            value={position}
            onChange={this.handleChange}
            required
            dirty
          />

          <Field
            label={t('DEPARTMENT')}
            name="department"
            value={department}
            onChange={this.handleChange}
            required
            dirty
          />

          <Field
            label={t('ADDRESS')}
            name="address"
            value={address}
            onChange={this.handleChange}
          />

          <Field
            label={t('ZIPCODE')}
            name="zipcode"
            value={zipcode}
            onChange={this.handleChange}
          />

          <Field
            label={t('CITY')}
            name="city"
            value={city}
            onChange={this.handleChange}
          />

          <FormGroup>
            <Col smOffset={2} sm={4}>
              <ButtonWithLoader
                type="submit"
                disabled={!enabled}
                onClick={this.save}
                bsStyle="primary"
                loading={loading}>
                {t('SAVE')}
              </ButtonWithLoader>
            </Col>
          </FormGroup>
        </Form>
      </Grid>
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value }); // update state with changed values
  }

  save(e) {
    e.preventDefault();
    this.props.dispatch(updateProfile(this.state));
  }
}

Profile.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  company_name: PropTypes.string,
  position: PropTypes.string,
  department: PropTypes.string,
  address: PropTypes.string,
  zipcode: PropTypes.string,
  city: PropTypes.string,
  phone: PropTypes.string,
  owned_brands: PropTypes.array,
  plan: PropTypes.shape({
    valid_until: PropTypes.bool,
    retailers: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string
      })
    )
  }).isRequired,
  loading: PropTypes.bool,
  dispatch: PropTypes.func.isRequired
};

function select(state) {
  return {
    ...state.user
  };
}

export default connect(select)(Profile);
