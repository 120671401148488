import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import moment from '../../lib/moment-with-locales';

import { t } from '../../i18n';

function ProductInfo(props) {
  const {
    product_listed_ingredients,
    product_nutrients,
    product_nutrients_prepared,
    producer_name,
    retailers,
    refrigeration_type,
    package_name,
    attribute_metadata
  } = props;
  return (
    <div>
      <h3>{t('NUTRIENTS')}</h3>
      <p>
        <Source {...attribute_metadata.product_nutrients} />
        <PreparedNutrients
          value={product_nutrients_prepared}
          className="pull-right"
        />
      </p>
      <Table>
        <tbody>
          {product_nutrients.map((item, index) => (
            <tr key={index}>
              <td>
                {(item.code && t(`NUTRIENT_${item.code.toUpperCase()}`)) ||
                  item.name}
              </td>
              <td>{item.value}</td>
              <td>{item.unit}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <h3>{t('PRODUCER')}</h3>
      <p>{producer_name}</p>
      <h3>{t('SUPERMARKET')}</h3>
      {retailers.map((item, index) => (
        <div key={index}>{item.name}</div>
      ))}
      <h3>{t('INGREDIENTS')}</h3>
      <Source {...attribute_metadata.product_listed_ingredients} />
      {product_listed_ingredients.map((item, index) => (
        <div key={index}>{item.name}</div>
      ))}
      <h3>{t('REFRIGERATION_TYPE')}</h3>
      <p>{refrigeration_type}</p>
      <h3>{t('PACKAGING')}</h3>
      <p>{package_name}</p>
    </div>
  );
}

ProductInfo.propTypes = {
  product_listed_ingredients: PropTypes.array,
  product_nutrients: PropTypes.array,
  product_nutrients_prepared: PropTypes.bool,
  producer_name: PropTypes.string,
  retailers: PropTypes.array,
  refrigeration_type: PropTypes.string,
  package_name: PropTypes.string,
  attribute_metadata: PropTypes.object
};

ProductInfo.defaultProps = {
  product_listed_ingredients: [],
  product_nutrients: [],
  retailers: [],
  attribute_metadata: {
    product_nutrients: {},
    product_listed_ingredients: {}
  }
};

function Source({ source, confirmed_at, ...rest }) {
  return (
    <span {...rest}>
      {source && (
        <em>
          {t('SOURCE')}: {source}
        </em>
      )}
      {confirmed_at && <em>, {moment(confirmed_at).format('LL')}</em>}
    </span>
  );
}

Source.propTypes = {
  source: PropTypes.string,
  confirmed_at: PropTypes.string
};

function PreparedNutrients({ value, ...rest }) {
  if (typeof value !== 'boolean') return null;
  return (
    <em {...rest}>
      {value ? t('NUTRIENTS_PREPARED') : t('NUTRIENTS_UNPREPARED')}
    </em>
  );
}

PreparedNutrients.propTypes = {
  value: PropTypes.bool
};

export default ProductInfo;
