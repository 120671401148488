import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Media, Image } from 'react-bootstrap';
import BrandLabel from '../BrandLabel';
import NoResults from '../NoResults';
import { t } from '../../i18n';

function ProductList(props) {
  const { items, onRemove } = props;
  if (!items.length) {
    return <NoResults center>{t('NO_COMPARISON_PRODUCTS')}</NoResults>;
  }
  return (
    <ul className="list list-unstyled list-qmi">
      {items.map((product, index) => (
        <li key={index}>
          <Media>
            <Media.Left>
              <Image
                src={product.image.thumb || '/images/placeholder.svg'}
                width={40}
                style={{ maxHeight: 60 }}
              />
            </Media.Left>
            <Media.Body>
              <Link to={`/products/${product.id}`}>{product.name}</Link>
              <div className="product-meta text-muted">
                <i className="glyphicon glyphicon-barcode"></i>{' '}
                <small>{product.barcode}</small>{' '}
                <BrandLabel brand_id={product.brand_id}>
                  {product.brand_name}
                </BrandLabel>
              </div>
              <a
                href=""
                className="remove-product brand-danger"
                onClick={(e) => onRemove(e, product)}>
                <i className="glyphicon glyphicon-trash"></i>
              </a>
            </Media.Body>
          </Media>
        </li>
      ))}
    </ul>
  );
}

ProductList.propTypes = {
  items: PropTypes.array.isRequired,
  className: PropTypes.string,
  onRemove: PropTypes.func
};

export default ProductList;
